import EndpointProps from "interface/EndpointProps";
import Inquiry from "interface/Inquiry";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import { post } from "../ApiClient";
import { fetchApiData } from "../ApiHelper";

/**
 * Submits a contact inquiry.
 *
 * @param {Object} params - The parameters for submitting the contact inquiry.
 * @param {string} params.tag - The tag associated with the endpoint.
 * @param {Inquiry} params.data - The data payload containing the contact inquiry details.
 * @returns {Promise<boolean>} A promise that resolves to true if the submission is successful, otherwise false.
 *
 * @throws {Error} Throws an error if the fetch operation fails or if the data payload is missing.
 */
export async function submitContactInquiry({
    tag,
    data,
}: EndpointProps & { data: Inquiry }): Promise<boolean> {
    if (!data) {
        throw new Error("Invalid contact inquiry request: Missing data payload.");
    }

    const result = await fetchApiData<DefaultApiResponse>(
        tag,
        () => post<DefaultApiResponse>({ tag, url: "/contact/inquiry", body: data }),
        (responseData) => responseData
    );

    return result.response.status === "success";
}