import styles from 'styles/components/ToggleSwitch.module.sass'
import React, { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { alpha, styled } from '@mui/material/styles'

interface ToggleSwitchProps {
    selectedOption: string;
    options: string[]
    onOptionChange: (option: string) => void
}


/**
 * ToggleSwitch component renders a toggle switch with two options.
 * 
 * @component
 * @param {string[]} options - An array of two string options to be displayed in the toggle switch.
 * @param {(option: string) => void} onOptionChange - A callback function to handle the option change when a user selects one.
 * 
 * @example
 * <ToggleSwitch
 *   options={['Individual / Business', 'Education']}
 *   onOptionChange={(option) => console.log('Selected option:', option)}
 * />
 * 
 * @returns {JSX.Element} The JSX for the ToggleSwitch component.
 */
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ selectedOption, options, onOptionChange }) => {
    const [checked, setChecked] = useState<boolean>(selectedOption === options[1]);

    useEffect(() => {
        setChecked(selectedOption === options[1])
    }, [selectedOption, options])

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setChecked(isChecked); // Update local state based on the toggle
    
        // Set the selected option based on the checked state
        const selectedOption: string = isChecked ? options[1] || '' : options[0] || '';
    
        // Notify the parent component with the selected option
        onOptionChange(selectedOption);
    };
    
    const OrangeSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#FF5522',
          '&:hover': {
            backgroundColor: alpha('#FF5522', theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#FF5522',
        },
        '& .MuiSwitch-switchBase': {
            color: '#136A97',  // Default switch color
        },
        '& .MuiSwitch-track': {
            backgroundColor: '#367797', // Track color when unchecked
        },
    }));

    const capitalizeFirstLetter = (label: string) => {
        return label.charAt(0).toUpperCase() + label.slice(1);
    }
      
    return (
        <div className={styles['toggle-switch']}>
            <span className={`${styles['option-label']} ${selectedOption === options[0] ? styles['option-label-checked'] : ''}`}>
            {capitalizeFirstLetter(options[0] || '')}
            </span>
            <FormControlLabel
            control={
                <OrangeSwitch
                checked={checked}
                onChange={handleToggle}
                name="toggleSwitch"
                color="primary"
                />
            }
            label=""
            labelPlacement="top"
            />
            <span className={`${styles['option-label']} ${selectedOption === options[1] ? styles['option-label-checked'] : ''}`}>
            {capitalizeFirstLetter(options[1] || '')}
            </span>
        </div>
    )
}

export default ToggleSwitch;