import React, { useEffect } from 'react';
import styles from 'styles/components/Maintenance.module.sass'
import { useIsMaintenanceMode } from 'store/useMaintenanceStore';
import useMaintenanceStore from 'store/useMaintenanceStore';
import { systemUnderMaintenance } from 'api/MaintenanceApiService';

/**
 * @description: Maintenance component that displays a maintenance message when the system is under maintenance.
 * @module Maintenance
 * 
 * @example
 * import Maintenance from 'components/common/Maintenance';
 * 
 * const App = () => {
 *   return (
 *     <div>
 *       <Maintenance />
 *     </div>
 *   );
 * };
 */
const Maintenance: React.FC = () => {
    
    useEffect(() => {
        const checkMaintenanceStatus = async () => {
            const isUnderMaintenance = await systemUnderMaintenance('Maintenance');
            useMaintenanceStore.setState({ isMaintenanceMode: isUnderMaintenance });
        };

        checkMaintenanceStatus();
    }
    , []);

    const isMaintenanceMode = useIsMaintenanceMode();
    if (!isMaintenanceMode) {
        return null; // Render nothing if not in maintenance mode
    }
    return (
        <div className={styles['maintenance']}>
            <h1 className={styles['maintenance-title']}>🚧 System Maintenance in Progress 🚧</h1>
            <p className={styles['maintenance-text']}>
                We&apos;re currently performing scheduled maintenance. We apologize for any inconvenience this may cause and appreciate your patience.
            </p>
        </div>
    );
}

export default Maintenance;