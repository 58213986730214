import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from 'styles/components/PaymentForm.module.sass'
import ZeroneLoader from 'components/common/ZeroneLoader'
import StripePaymentForm from './StripePaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import accountTypes from 'constants/data/AccountTypes'
import AccountType from 'interface/AccountType'
import SignUpRequest from 'constants/api/models/SignUpRequest'
import { useToast } from 'provider/ToastProvider'
import processSignUpUser from 'api/AuthApiService'
import stripeErrorMessages from 'constants/data/StripeErrors'
import LocationState from 'interface/LocationState'
import ChangePlan from 'interface/ChangePlan'
import { logError } from 'services/logService/errorLogger'
import { processChangePlan } from 'api/SubscriptionApiService'
import PrimaryButton from 'components/common/PrimaryButton'
import { processSubscriptionResponse } from 'api/PaymentApiService'

type Discount = {
    percent: number;
    originalAmount: number;
    discountAmount: number;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
const currency = 'USD'
const MIN_VALID_AMOUNT: number = 5.5
const EXPECTED_PARAMS: number = 11
//const allowedAccountTypes = ['free', 'basic', 'sapphire', 'gold', 'platinum'] as const;

/**
 * The SignUpPaymentForm component handles the payment step during user registration. 
 * It fetches the client secret and payment details based on the selected account type 
 * and displays a secure payment form for users to complete their subscription.
 * 
 * @component
 * 
 * @example
 * // Usage example:
 * <SignUpPaymentForm />
 * 
 * @see ZeroneLoader - A loader component that provides user feedback during data fetching.
 * @see StripePaymentForm - A form for handling secure payment processing.
 * @see generateClientSecret - A utility function to generate a Stripe client secret.
 * @see Elements - A Stripe component wrapper for handling payment elements.
 * 
 * @requires react-router-dom - For navigation between pages.
 * @requires @stripe/react-stripe-js - For Stripe payment integration.
 * @requires @stripe/stripe-js - For loading Stripe configuration.
 * 
 * @returns {JSX.Element} - The rendered SignUpPaymentForm component.
 */
const SignUpPaymentForm: React.FC = () => {
    const { addToast } = useToast()
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState<string>('')
    const [clientSecret, setClientSecret] = useState<string | null>(null)
    const [amount, setAmount] = useState<number>(0.00)
    const [accountType, setAccountType] = useState<AccountType>()
    const [billingCycle, setBillingCycle] = useState<'monthly' | 'annual'>('monthly')
    const [accountReady, setAccountReady] = useState<boolean>(false)
    const [isFreeAccount, setFreeAccount] = useState<boolean>(false)
    const [discount, setDiscount] = useState<Discount | null>(null);
    
    const location = useLocation()
    const navigate = useNavigate()
    const { isPlanChange, ...accountParams } = (location.state || {}) as LocationState

    useEffect(() => {
        if (!accountParams || Object.keys(accountParams).length < EXPECTED_PARAMS) {
            navigate('/sign-up');
            return;
        }
    
        const currentAccountType = accountTypes.find(
            (account) => account.name === accountParams.accountType
        );
    
        if (currentAccountType) {
            setAccountType(currentAccountType);
            setBillingCycle(accountParams.billingCycle ?? 'monthly')
        } else {
            navigate('/sign-up');
        }
    }, [accountParams, navigate]);
    
    useEffect(() => {
        if (!accountType) return; // Ensure accountType is set before proceeding
    
        if (isPlanChange) {
            // Handle plan change
            handleChangeSubscription();
        } else {
            // Handle new signup
            handleNewSignup();
        }
    }, [accountType, isPlanChange]);
    
    

    const handleLogin = () => {
        navigate('/login')
    }

    const handleNewSignup = () => {
        setFreeAccount(accountType!.name === 'free');
        setMessage(accountType!.name === 'free' ? 'Preparing your account...' : 'Preparing your secure payment session...');
        
        const signupParams: SignUpRequest = {
            firstName: accountParams.firstName,
            lastName: accountParams.lastName,
            email: accountParams.email,
            gender: accountParams.gender,
            countryCode: accountParams.countryCode,
            phone: accountParams.phone,
            password: accountParams.password,
            confirmPassword: accountParams.confirmPassword,
            accountType: accountParams.accountType as 'free' | 'basic' | 'sapphire' | 'gold' | 'platinum',
            billingCycle: accountParams.billingCycle as 'monthly' | 'annual',
            companyName: accountParams.companyName || '',
            jobTitle: accountParams.jobTitle || '',
        };
    
        processRequest(signupParams, processSignUpUser, 'Payment form is ready for your subscription.', 'Signup failed. Please try again.');
    };

    useEffect(() => {
        if (accountType) {
            setFreeAccount(accountType.name === 'free')
        }
    }, [accountType, navigate])

    const handleChangeSubscription = () => {
        setMessage('Updating your plan...');
        setLoading(true);
    
        const params: ChangePlan = {
            accountType: accountParams.accountType as 'free' | 'basic' | 'sapphire' | 'gold' | 'platinum',
            billingCycle: accountParams.billingCycle as 'monthly' | 'annual'
        };
    
        processRequest(params, processChangePlan, 'Plan updated successfully. Please complete the payment to activate your subscription.', 'Error updating plan. Please log in to your account and update on your profile.');
    };


    /* eslint-disable @typescript-eslint/no-redundant-type-constituents */
    /* eslint-disable @typescript-eslint/no-unsafe-return */
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    /* eslint-disable @typescript-eslint/no-unsafe-function-type */

    const processRequest = async (params: ChangePlan | SignUpRequest, processFunction: Function, successMessage: string, errorMessage: string) => {
        setLoading(true);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const response = await processFunction({ tag: 'UserSignup', data: params });
    
            if ('error' in response) {
                setMessage(response.error || errorMessage);
                setLoading(false);
                showToast('error', response.error || errorMessage);
                return;
            }
    
            if ('data' in response && response.data) {
                if (accountType!.name === 'free') {
                    setAccountReady(true);
                    setAmount(0.0);
                    setDiscount(null)
                    setClientSecret(null);
                    handlePaymentSuccess('');
                } else {
                    // Success response handling
                    /* eslint-disable @typescript-eslint/no-unsafe-call */
                    if (response.data.stripePaymentMethod?.trim() && typeof response.data.amount === 'number' && response.data.amount >= MIN_VALID_AMOUNT) {
                        setClientSecret(response.data.stripePaymentMethod);
                        setAmount(response.data.amount);
                        showToast('success', successMessage);
                        setAccountReady(true)
                        // Set discount
                        if (response.data.discount) {
                            setDiscount(response.data.discount as Discount)
                        } else {
                            setDiscount(null)
                        }
                    } else {
                        setMessage('We encountered an issue with your payment setup. Please contact support or try again.');
                        showToast('error', 'We encountered an issue with your payment setup. Please contact support or try again.');
                    }
                }
            } else {
                setMessage('Unexpected response format. Please try again.');
                showToast('error', 'Unexpected response format. Please try again.');
            }
            setLoading(false)
        } catch (error) {
            logError(error instanceof Error ? error.message : 'Unexpected error', { error: error instanceof Error ? error.stack : '' }, 'SignUpPaymentForm');
            setMessage(errorMessage);
            setLoading(false);
        }
    };
    /* eslint-enable @typescript-eslint/no-redundant-type-constituents */
    /* eslint-enable @typescript-eslint/no-unsafe-return */
    /* eslint-enable @typescript-eslint/no-unsafe-argument */
    /* eslint-enable @typescript-eslint/no-unsafe-function-type */

    const handlePaymentSuccess = async (paymentId: string) => {
        setLoading(true)
        await processSubscriptionResponse({ paymentId, status: 'succeeded' })
        setLoading(false)
                
        /**
         * Trigger auth change event to update user subscription.
         * This is a workaround to update the user subscription in the auth context.
         * This is because the user subscription is not updated in the auth context after payment is successful.
        */
        window.dispatchEvent(new Event('authChange'))
        navigate('/success', {
            state: {
                title: 'All Set! Veryify your email.',
                message: accountType?.name === 'free' 
                ? 'We’re setting up your free account. It will be ready shortly. Thank you for joining us!' 
                : `Thank you! Your payment has been received, and we’re completing the setup of your account. It will be ready shortly.`,
                note: `If you didn't see the email in your inbox, please check your spam folder.`,
                redirectTo: '/',
                messageType: 'success',
                meta: {
                    accountType,
                    paymentId,
                }
            }
        })
    }

    const handlePaymentFailure = async (errorMessage: string) => {
        setLoading(true)
        await processSubscriptionResponse({ status: 'failed' })
        setLoading(false)

        if (typeof errorMessage !== 'string' || !errorMessage) {
            showToast('error', 'Something went wrong. Please try again.')
            return;
        }

        const error = errorMessage.toLowerCase()
        const defaultError = 'Payment failed. Please contact support if the issue persists.'

        const errorKey = Object.keys(stripeErrorMessages).find((key) =>
            typeof error === 'string' && error.includes(key)
        )

        if (errorKey) {
            showToast('error', stripeErrorMessages[errorKey] || defaultError)
        } else {
            showToast('error', 'Payment failed. Please contact support if the issue persists.')
        }
    }

    const handleChangePlan = () => {
        if (!accountParams || Object.keys(accountParams).length < EXPECTED_PARAMS) {
            showToast('error', 'Unable to change plan. Please log in to your account and update on your profile.')
            navigate('/sign-up')
            return
        }

        if (!accountParams.accountType) {
            showToast('error', 'No account type selected. Please choose a valid accout type.')
            navigate('/account-type', {
                state: {
                    ...accountParams
                }
            })
        }

        navigate('/account-type', {
            state: {
                ...accountParams,
                isPlanChange: true
            }
        });
        showToast('info', 'You can now select a different account plan.')
    }

    const handleCancelPayment = async () => {
        setLoading(true)
        await processSubscriptionResponse({ status: 'canceled' })
        setLoading(false)

        const messg = "Payment proces cancelled. Your account has been reverted to the free plan. You can update your payment details anytime in your profile."
        setMessage(messg)
        showToast('warning', messg)

        navigate('/success', {
            state: {
                message: messg,
                redirectTo: '/',
                messageType: 'success'
            }
        })
    }

    const showToast = (type: 'error' | 'success' | 'warning' | 'info', toast: string) => {
        addToast({
            message: toast,
            type
        })
    }

    /* eslint-disable @typescript-eslint/no-misused-promises */
    return (
        <div className={styles['main-payment-form']}>
            {!isFreeAccount ? null : ( 
                <div className={styles['header-container']}>  
                    <div className={styles['logo-container']}>
                        <Link to="/">
                            <img src={zeroneLogoDark} className={styles['zerone-logo']} alt="Zerone AnalytiQs Logo" />
                        </Link>
                    </div>
                    <h2 className={styles['form-title']}>{!isFreeAccount? 'Enter payment information': 'Welcome to ZeroneAnalytiQs' }</h2>
                    <p className={styles['form-subtitle']}>{!isFreeAccount? 'We will automatically bill each month': 'Your free account is being prepared for you. Hang tight!'}</p>
                </div>
            )}
            {loading ? (
                <div className="loader">
                    <ZeroneLoader text={message} />
                </div>
            ) : accountType?.name === 'free' && accountReady ? (
                <p className={styles['message']}>Account ready! Redirecting...</p>
            ) : clientSecret && accountType ? (
                <div className={styles['main-wrapper']}>
                    <div className={styles['summary-section']}>
                        <div className={styles['top-summary']}>
                            <label className={styles['radio-label']}>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value={accountType.name}
                                    checked={true}
                                    className={styles['radio-input']}
                                />
                                <span 
                                    className={styles['radio-text']}
                                    style={{ color: accountType.color }}
                                >
                                    {accountType.name}
                                </span>
                            </label>
                            <div className={styles['right']}>
                                <p className={styles['amount']}>{currency} {isNaN(amount) ? '0.00': amount.toFixed(2) || '0.00'}</p>
                                <span className={styles['subscription-type']}>{billingCycle === 'monthly' ? 'per month' : 'per year'}</span>
                            </div>
                        </div>
                        <p className={styles['account-description']}>{accountType.description}</p>
                        <span className={styles['change-plan']} onClick={handleChangePlan}>Change plan</span>
                    </div>
                    <div className={styles['divider']} />
                    <div className={styles['payment-section']}>
                        {discount !== null ? (
                            <div className={styles['discount-table']}>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Standard Price:</div>
                              <div className={styles['table-cell']}>{currency} {discount.originalAmount.toFixed(2)}</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Discount Applied:</div>
                              <div className={styles['table-cell']}>{discount.percent}%</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Discount Amount:</div>
                              <div className={styles['table-cell']}>{currency} {discount.discountAmount.toFixed(2)}</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Total After Discount:</div>
                              <div className={styles['table-cell']}>{currency} {(discount.originalAmount - discount.discountAmount).toFixed(2)}</div>
                            </div>
                          </div>
                        ) : null}
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <StripePaymentForm
                                clientSecret={clientSecret}
                                onPaymentSuccess={handlePaymentSuccess}
                                onPaymentFailure={handlePaymentFailure}
                                onCancelPayment={handleCancelPayment}
                            />
                        </Elements>
                    </div>
                </div>
            ) : (
                <div className={styles['message-section']}>
                    <p className={styles['message']}>{message}</p>
                    {message.toLowerCase().includes('log in') && (            
                        <PrimaryButton
                            text='Login'
                            primaryColor='#FF5522'
                            textColor='#FFFFFF'
                            hoverColor='#FFFFFF'
                            hoverTextColor='#FF5522'
                            doSomething={handleLogin}
                        />
                    )}
                </div>
            )}
        </div>
    )
};

export default SignUpPaymentForm;

