import { BlogContent } from "interface/BlogContent";
import { LindaArthur } from "../author/LindaArthur";

export const illegalMining: BlogContent = {
    id: "1d84eb88-c142-4b88-97e3-a6241b90a4c1",
    category: "Countries & Economies",
    title: "Are Our Neglections on Illegal Mining Catching Up With Us?",
    catchyPhrase: "Over 90% of Ghanaians say illegal mining is destroying our water, and 100% agree it's time to act—now!",
    paragraphs: [
        {
            text: 'llegal mining, commonly known as "galamsey," has been a persistent issue in Ghana for many years. While it has always been a topic of concern, recent developments in Cape Coast and Elmina indicate that the problem may be reaching a critical point. The Ghana Water Company Limited (GWCL) has warned of severe water supply challenges due to the pollution of the Pra River caused by illegal mining activities. As the consequences of galamsey become more apparent, the question arises: Are neglections on illegal mining catching up with us?'
        },
        {
            section: 'Survey Insights',
            title: 'Public Sentiment ',
            text: 'To understand public opinion on the impact of illegal mining on water supply and the urgency of addressing the issue, Zerone Analytiqs conducted a survey among the Ghanaian populace. The results paint a concerning picture of the situation.'
        },
        {
            title: 'Impact on Communities',
            text: 'A significant majority of respondents - 93.1%, believe that illegal mining has had a profound effect on their water supply. One respondent noted, "Looking at the nature of the destruction so far, I think a strict and effective taskforce will oversee that a ban has been placed on all illegal mining activities in the country." This sentiment echoes the impending threat of water supply in Cape Coast and Elmina reported by the Ghana Water Company Limited (GWCL). In their report the water treatment plant\'s capacity has been drastically reduced due to the high levels of siltation and pollution from the Pra river.',
            highlightedWords: ['93.1%', 'Looking at the nature of the destruction so far, I think a strict and effective taskforce will oversee that a ban has been placed on all illegal mining activities in the country'],
            chartData: {
                chartType: 'bar',
                title: 'Perception on the Impact of Illegal Mining on Water Bodies',
                labels: ['Yes', 'No', 'Unsure'],
                values: [93, 3, 3],
                annotation: 'Do you believe that galamsey has significantly impacted water supply in your area.'
            }
        },
        {
            title: 'Urgency of Action',
            text: 'When asked about the urgency of addressing the issue, 100% of respondents indicated that it is "very urgent" with many emphasising the need for immediate intervention from both the government and local communities. As one participant put it, "We cannot afford to wait any longer. The longer we ignore this issue, the more destruction, difficult and expensive it will be to fix." This sense of urgency aligns with GWCL\'s call for all stakeholders to join the fight against galamsey to protect land and water resources.',
            highlightedWords: ['very urgent', 'We cannot afford to wait any longer. The longer we ignore this issue, the more destruction, difficult and expensive it will be to fix.'],
            paragraphImage: {
                url: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/blogs%2Fillegal-mining%2Fillegal-mining-urgency.png?alt=media&token=cf427745-5796-4e64-9ba0-cce79d401166',
                position: 'end'
            }
        },
        {
            title: 'Efforts to Combat Illegal Mining',
            text: 'Despite the dire situation, survey responses indicate mixed feelings about the effectiveness of current efforts to combat illegal mining. 41% of respondents believe that while efforts are being made, they have not been effective in curbing the problem. About 55% of the respondents indicated that they see no ongoing effort or effective measures geared towards solving this canker in Ghana. This suggests that while awareness is growing, the implementation of solutions has not kept pace with the severity of the issue.',
            highlightedWords: ['41%', '55%'],
            chartData: {
                chartType: 'pie',
                title: 'Effort to combat Illegal Mining',
                labels: ['No efforts observed', 'Yes, but not effective', 'Yes, effective'],
                values: [55.17, 41.38, 3.45],
            }
        },
        {
            title: 'Long-Term Solutions',
            text: 'In solving this canker, a collective response from respondents suggested punishment and strict enforcement of laws while prioritising education and creating alternative livelihoods for those involved in galamsey. They believe that until we address the root causes, this problem will continue to plague us.',
            paragraphImage: {
                url: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/blogs%2Fillegal-mining%2Fwhat-should-we-do.png?alt=media&token=6438cd59-c340-40c4-8760-75bb1e8989c8',
                position: 'start'
            }
        },
        {
            title: 'The Future',
            text: 'The impact of illegal mining on Ghana’s water resources, particularly in Cape Coast and Elmina, is a clear indication that past neglect is catching up with us. The voices of the community, as captured in our survey, underscore the urgent need for action. The fight against galamsey requires not only government intervention but also the active participation of all citizens. If we fail to act now, the cost to our environment, our health, and our future will only grow.'
        }
    ],
    attributions: [
        {
            text: "Source of Data: Zerone Data",
            href: "https://zeronedata.io/"
        },
        {
            text: "Photo by Vladimir Patkachakov on Unsplash",
            href: "https://unsplash.com/photos/yellow-and-black-heavy-equipment-on-brown-sand-under-blue-sky-during-daytime-ZAP1duyEIR4"
        },
        {
            text: "Photo by Dominik Vanyi on Unsplash",
            href: "https://unsplash.com/photos/photography-of-excavators-at-mining-area-Mk2ls9UBO2E"
        }
    ],
    author: LindaArthur,
    meta: {
        createdDate: new Date('2024-09-27'),
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/blogs%2Fillegal-mining%2Fmining.avif?alt=media&token=e42afde9-b394-42a1-8d38-a8be65f1a2a1',
        readTime: '4 min read'
    },
    trending: true
}