import React, { useEffect, useState } from 'react'
import styles from 'styles/components/CancelSubscriptionConfirmationCard.module.sass'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/AuthContext'
import ReusableIcon from 'components/common/ReusableIcon'
import PrimaryButton from 'components/common/PrimaryButton'
import SecondaryButton from 'components/common/SecondaryButton'
import { useToast } from 'provider/ToastProvider'
import { processChangePlan } from 'api/SubscriptionApiService'
import ZeroneLoader from 'components/common/ZeroneLoader'
import { logError } from 'services/logService/errorLogger'

interface CancelSubscriptionConfirmationCardProps {
    onClose: () => void
}

/**
 * CancelSubscriptionConfirmationCard component displays a confirmation card
 * for canceling a subscription.
 *
 * This card is displayed when a user attempts to cancel their subscription.
 *
 * @component
 * @param {CancelSubscriptionConfirmationCardProps} props - The properties for the CancelSubscriptionConfirmationCard component.
 * @param {() => void} props.onClose - The callback function to close the card.
 *
 * @example
 * // Example usage:
 * <CancelSubscriptionConfirmationCard
 *   onClose={() => console.log('Close button clicked')}
 * />
 *
 * @returns {JSX.Element} The rendered CancelSubscriptionConfirmationCard component.
 */
const CancelSubscriptionConfirmationCard: React.FC<CancelSubscriptionConfirmationCardProps> = ({ onClose }) => {
    const { addToast } = useToast()
    const { user, userSubscription } = useAuth()
    const [planName, setPlanName] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const navigate = useNavigate()

    useEffect(() => {
        if (!user || !userSubscription || userSubscription.name === 'free') {
            navigate('/login')
        } else {
            const subscriptionPlan = userSubscription.name.charAt(0).toUpperCase() + userSubscription.name.slice(1)
            setPlanName(subscriptionPlan)
        }
    }, [user, userSubscription, navigate])

    const downgradePlanToFree = async () => {
        setLoading(true)
        setMessage('Canceling your subscription...')
        try {
            const result = await processChangePlan({
                tag: 'CancelSubscriptionConfirmation',
                data: {
                    accountType: 'free',
                    billingCycle: userSubscription?.billingCycle || 'monthly',
                }
            })

            if ('error' in result) {
                addToast({
                    type: 'error',
                    message: 'An error occurred while canceling your subscription. Please try again later.'
                })
            } else {
                addToast({
                    type: 'success',
                    message: 'Your subscription has been canceled successfully.'
                })
                /**
                 * Trigger auth change event to update user subscription.
                 * This is a workaround to update the user subscription in the auth context.
                 * This is because the user subscription is not updated in the auth context after payment is successful.
                */
                window.dispatchEvent(new Event('authChange'))
            }
        } catch (error) {
            logError('Error canceling subscription.', { error }, 'CancelSubscriptionConfirmationCard')
            addToast({
                type: 'error',
                message: 'An error occurred while canceling your subscription. Please try again later.'
            })
        } finally {
            setMessage('')
            setLoading(false)
            onClose()
        }
    }

    const handleCancelSubscription = () => {
        downgradePlanToFree()
    }

    const handleKeepSubscription = () => {
        addToast({
            type: 'success',
            message: 'Thank you for staying with us!'
        })
        onClose()
    }

    return (
        <div className={styles['cancel-subscription-confirmation-card']}>
            <button className={styles['close-button']} onClick={onClose}>
                <ReusableIcon
                    icon={'qlementine-icons:close-16'}
                    className={styles['close-icon'] || 'close-icon'}
                    color={'#707070'}
                />
            </button>
            <div className={styles['main-content']}>
                <div className={styles['right-wrapper']}>
                    <h3 className={styles['title']}>Are you sure you want to cancel your subscription?</h3>
                    <p className={styles['description']}>
                        Canceling your subscription will remove your access to all {planName} plan features and reports. You will still have access to your account until the end of your billing cycle. Your account will be downgraded to the free plan after that.
                    </p>
                </div>
            </div>
            <div className={styles['button-group']}>
                <PrimaryButton
                    text="Yes, Cancel Subscription"
                    primaryColor="#014365"
                    textColor="#e3e3e3"
                    hoverColor="#FF5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={() => handleCancelSubscription()}
                />
                <SecondaryButton
                    text="No, Keep Subscription"
                    primaryColor="transparent"
                    textColor="#FF5522"
                    hoverColor="#FF5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={() => handleKeepSubscription()}
                />
            </div>
            
            {loading && (
                <div className={styles['loader']}>
                    <ZeroneLoader text={message} />
                </div>
            )}
        </div>
    )
}

export default CancelSubscriptionConfirmationCard