import { logError } from "services/logService/errorLogger";

interface ReportPurchaseUpdate {
    paymentId: string;
    status: 'failed' | 'paid' | 'cancelled';
}

const TAG = 'validateAndSanitizeReportPurchaseUpdate';

/**
 * Validates and sanitizes the report purchase update data.
 *
 * @param {ReportPurchaseUpdate} data - The report purchase update data to validate and sanitize.
 * @returns {ReportPurchaseUpdate | null} - The validated and sanitized data, or null if validation fails.
 */
export const validateAndSanitizeReportPurchaseUpdate = (data: ReportPurchaseUpdate): ReportPurchaseUpdate | null => {
    const { paymentId, status } = data;
    let sanitizedStatus = status;

    // Validate paymentId
    const paymentIdPattern = /^pi_[a-zA-Z0-9]{24}$/;
    if (!paymentId || typeof paymentId !== 'string' || !paymentIdPattern.test(paymentId)) {
        logError('Invalid payment ID.', { paymentId }, TAG);
        return null;
    }

    // Validate status
    if (!status || (status !== 'failed' && status !== 'paid' && status !== 'cancelled')) {
        logError('Invalid payment status.', { status }, TAG);
        return null;
    } else if (status === 'failed' || status === 'cancelled') {
        sanitizedStatus = 'failed';
    }

    return {
        paymentId,
        status: sanitizedStatus as 'failed' | 'paid',
    };
}

