import React, { useEffect, useState } from 'react';
import styles from 'styles/components/AddInterestedIndustriesCard.module.sass';
import endpoints from 'constants/api/Endpoints';
import { logError } from 'services/logService/errorLogger';
import SearchBar from 'components/common/SearchBar';
import PrimaryButton from 'components/common/PrimaryButton';
import ReusableIcon from 'components/common/ReusableIcon';
import Industry from 'interface/Industry';
import { useToast } from 'provider/ToastProvider';

const tag = 'AddInterestedIndustriesCard';

interface AddInterestedIndustriesCardProps {
    setShowAddIndustries: (show: boolean) => void;
    userIndustries: Industry[];
    setLoading: (loading: boolean) => void;
    setFieldsUpdated: (updated: boolean) => void;
}

/**
 * AddInterestedIndustriesCard component - Displays a card for adding interested industries.
 *
 * This component includes a button to add interested industries.
 *
 * @component
 * @param {AddInterestedIndustriesCardProps} props - The properties for the AddInterestedIndustriesCard component.
 * @param {Industry[]} props.userIndustries - The list of industries that the user is interested in.
 *
 * @example
 * // Example usage:
 * <AddInterestedIndustriesCard setShowAddIndustries={setShowAddIndustries} userIndustries={userIndustries} />
 *
 * @returns {JSX.Element} The rendered AddInterestedIndustriesCard component.
 */
const AddInterestedIndustriesCard: React.FC<AddInterestedIndustriesCardProps> = ({ setShowAddIndustries, userIndustries, setLoading, setFieldsUpdated }) => {
    const [industriesHash, setIndustriesHash] = useState<Map<string, string>>(new Map())
    const [filteredHash, setFilteredHash] = useState<Map<string, string>>(new Map())
    const [newIndustries, setNewIndustries] = useState<string[]>([]);
    const [query, setQuery] = useState<string>('');
    const { addToast } = useToast();
    
    const fetchIndustries = async () => {
        try {
            const industries = await endpoints.getAllIndustries({ tag });
            const hash = new Map<string, string>();
            industries.forEach((industry) => {
                if (!userIndustries.includes(industry)) {
                    hash.set(industry.industry, industry.id);
                }
            });
            setIndustriesHash(hash);
        } catch (error) {
            logError('Failed to fetch industries', { error }, tag);
        }
    }

    useEffect(() => {
        fetchIndustries();
    }, []);

    useEffect(() => {
        if (query) {
            const filteredIndustries = Array.from(industriesHash.entries()).filter(([industry]) => industry.toLowerCase().includes(query.toLowerCase()));
            setFilteredHash(new Map(filteredIndustries));
        } else {
            setFilteredHash(new Map(industriesHash));
        }
    }, [industriesHash, query]);

    const handleSearchChange = (query: string) => {
        setQuery(query);
    }

    const handleIndustryClick = (selectedId: string) => {
        const isSelected = newIndustries.includes(selectedId);
        if (isSelected) {
            setNewIndustries(newIndustries.filter(i => i !== selectedId));
        } else {
            setNewIndustries([...newIndustries, selectedId]);
        }
    };

    const handleSaveChanges = async () => {
        if (newIndustries.length === 0) {
            addToast({
                type: 'info',
                message: 'No industries selected.'
            });
            return;
        }
        setLoading(true);
        try {
            const response = await endpoints.addInterestedIndustries({ tag, industries: newIndustries });
            if (response.success) {
                addToast({
                    type: 'success',
                    message: 'Industries added successfully!'
                });
                setFieldsUpdated(true);
            } else {
                addToast({
                    type: 'error',
                    message: 'Failed to add industries.'
                });
            }
        } catch (error) {
            logError('Failed to add industries', { error }, tag);
            addToast({
                type: 'error',
                message: 'An error occurred while adding industries.'
            });
        } finally {
            setShowAddIndustries(false);
            setLoading(false);
        }
    }

    return (
        <div className={styles['add-interested-industries-card']}>
            <div className={styles['card-header']}>
                <div className={styles['button-container']}>
                    <button className={styles['close-button']} onClick={() => setShowAddIndustries(false)}>
                        <ReusableIcon
                            icon={'qlementine-icons:close-16'}
                            className={styles['close-icon'] || 'close-icon'}
                            color={'#707070'}
                        />
                    </button>
                </div>
                <p className={styles['card-header-text']}>Select Industries</p>
            </div>
            <div className={styles['search-bar']}>
                <SearchBar
                    placeholder='Search industries'
                    onSearch={handleSearchChange}
                />
            </div>
            <div className={styles['card-content']}>
                <ul className={styles['industries-list']}>
                    {Array.from(filteredHash.entries()).map(([industry, id]) => (
                        <li key={id} className={`${styles['industry-item']} ${newIndustries.includes(id) ? styles['selected'] : ''}`} onClick={() => handleIndustryClick(id)}>
                            {industry}
                        </li>
                    ))}
                </ul>
                {industriesHash.size === 0 && (
                    <div className={styles['no-industries-message']}>
                        No industries available at the moment.
                    </div>
                )}
            </div>
            <div className={styles['card-footer']}>
                <PrimaryButton
                    text='Save Changes'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    icon={''}
                    doSomething={() => {handleSaveChanges()}}
                    width='100%'
                />
            </div>

        </div>
    );
}

export default AddInterestedIndustriesCard;
