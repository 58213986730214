/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import endpoints from "constants/api/Endpoints";
import { SignUpData } from "constants/api/models/SignUpResponse";
import ChangePlan from "interface/ChangePlan";
import EndpointProps from "interface/EndpointProps";
import StripePaymentIntentResult from "interface/StripePaymentIntentResult";
import SubscriptionInvoice from "interface/SubscriptionInvoice";
import UserSubscription from "interface/UserSubscription";
import { logError } from "services/logService/errorLogger";

const fileName = 'SubscriptionApiService'

/**
 * Processes a subscription plan change for a user.
 * 
 * @param {EndpointProps & { data: ChangePlan }} param0 - The request properties, including a tag and the change plan data.
 * @returns {Promise<{ data: SignUpData } | { error: string | null }>} A promise resolving to either the updated sign-up data or an error message.
 * 
 * @throws Will log an error if the process fails due to validation issues, server errors, or unexpected errors.
 * 
 * @example
 * const result = await processChangePlan({
 *   tag: "UpdateSubscription",
 *   data: {
 *     planId: "premium",
 *     userId: "12345",
 *   },
 * });
 * if (result.error) {
 *   console.error("Error:", result.error);
 * } else {
 *   console.log("Success:", result.data);
 * }
 */
export async function processChangePlan({
    tag,
    data,
}: EndpointProps & { data: ChangePlan }): Promise< { data: SignUpData } | { error: string | null }> {
    if (!data) {
        return {
            error: "Please provide all required details to update your account.",
        };
    }

    try {
        const result = await endpoints.updateSubscriptionPlan({ tag, data });

        if (result.error) {
            logError("Error creating user account.", { result }, tag);

            if (typeof result.error === "string") {
                const serverError = result.error.toLowerCase();
                if (serverError.includes("validation")) {
                    return {
                        error: "Some required fields are missing or invalid. Please check your details and try again. You can also update your details in your profile..",
                    };
                }
                return {
                    error: "We encountered an issue while processing your request. Please try again. You can still log in and manage your profile.",
                };
            }

            return {
                error: "An unexpected error occurred while processing your request. Please try again later. You can log in and update your profile in the meantime.",
            };
        }

        const defaultResponse: SignUpData = {} as SignUpData
        const formattedResponse: SignUpData = (result.data as unknown as SignUpData) ?? defaultResponse

        return { data: formattedResponse }
    } catch (error) {
        logError(
            "Unexpected error during signup.",
            { error: error instanceof Error ? error.message : "Unknown error" },
            tag
        );
        return {
            error: "We are unable to process your request at the moment. Please check your connection and try again later. You can still log in and update your profile.",
        };
    }
}

/**
 * Fetches the user's current subscription details.
 * 
 * @returns {Promise<UserSubscription | null>} A promise resolving to the user's subscription data if successful, or null if an error occurs.
 * 
 * @throws Logs an error if the retrieval process encounters any issues.
 * 
 * @example
 * const subscription = await fetchUserSubscription();
 * if (subscription) {
 *   console.log("User subscription:", subscription);
 * } else {
 *   console.error("Failed to fetch subscription.");
 * }
 */
export const fetchUserSubscription = async (): Promise<UserSubscription | null> => {
    try {
        const result = await endpoints.fetchSubscriptionData({ tag: fileName })
        if (result.success && result.data) {
            return result.data
        }
        return null
    } catch (error) {
        logError(
            'Error retrieving subscription.',
            { error },
            fileName
        )
        return null
    }
}

/**
 * Fetches the user's subscription invoices based on the specified status.
 * 
 * @param {EndpointProps & { status: 'succeeded' | 'processing' | 'canceled' | 'failed' }} param0 - The endpoint properties and invoice status.
 * @returns {Promise<SubscriptionInvoice[]>} A promise resolving to an array of subscription invoices.
 * 
 * @throws Logs an error if the fetch operation fails.
 * 
 * @example
 * const invoices = await fetchSubscriptionInvoices({ tag: 'SubscriptionInvoices', status: 'succeeded' });
 * console.log('Invoices:', invoices);
 * */
export async function fetchSubscriptionInvoices({
    tag,
    status,
}: EndpointProps & { status: 'succeeded' | 'processing' | 'canceled' | 'failed' }): Promise<SubscriptionInvoice[]> {
    try {
        const result = await endpoints.getSubscriptionInvoices({ tag, status })
        if (result.success && result.data) {
            return result.data ? result.data : []
        }
        logError(
            'Failed to fetch subscription invoices.',
            { error: result.error },
            tag
        )
        return []
    } catch (error) {
        logError(
            'Error fetching subscription invoices.',
            { error },
            tag
        )
        return []
    }
}

/**
 * Initiates the checkout process for a subscription.
 * 
 * @param {EndpointProps} param0 - The endpoint properties.
 * @returns {Promise<StripePaymentIntentResult | null>} A promise resolving to the Stripe payment intent result if successful, or null if an error occurs.
 * 
 * @throws Logs an error if the checkout process encounters any issues.
 * 
 * @example
 * const checkoutResult = await checkOutSubscription({ tag: 'CheckoutSubscription' });
 * if (checkoutResult) {
 *   console.log("Checkout initiated:", checkoutResult);
 * } else {
 *   console.error("Failed to initiate checkout.");
 * }
 */
export async function checkOutSubscription({ tag} : EndpointProps): Promise<StripePaymentIntentResult | null> {
    try {
        const result = await endpoints.checkOutSubscription({ tag })
        if (result.success && result.data) {
            return result.data
        }
        logError(
            'Failed to fetch checkout subscription.',
            { error: result.error },
            tag
        )
        return null
    } catch (error) {
        logError(
            'Error fetching checkout subscription.',
            { error },
            tag
        )
        return null
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */