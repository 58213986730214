/**
 * Firebase configuration
 * 
 * This file contains the configuration for Firebase.
 * 
 * @packageDocumentation
 * @module src/constants/data/global/firebaseConfig
 * 
 * @example
 * import { firebaseConfig } from 'constants/data/global/firebaseConfig';
 * 
 * const app = initializeApp(firebaseConfig);
 * 
 * @see {@link https://firebase.google.com/docs/web/setup}
 * @see {@link https://firebase.google.com/docs/web/setup#add-sdks-initialize}
 * @see {@link https://firebase.google.com/docs/web/setup#config-object}
 * @see {@link https://firebase.google.com/docs/web/setup#add-sdks-initialize}
 */
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
};