import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';

/**
 * @description: Zustand store for managing maintenance mode state.
 * @module useMaintenanceStore
 * 
 * @example
 * import useMaintenanceStore from 'store/slices/useMaintenanceStore';
 * 
 * // Usage in a component
 * const MyComponent = () => {
 *  const isMaintenanceMode = useMaintenanceStore((state) => state.isMaintenanceMode);
 *  const setMaintenanceMode = useMaintenanceStore((state) => state.setMaintenanceMode);
 * 
 *  return (
 *    <div>
 *      <p>Maintenance Mode: {isMaintenanceMode ? 'On' : 'Off'}</p>
 *      <button onClick={() => setMaintenanceMode(!isMaintenanceMode)}>
 *          Toggle Maintenance Mode
 *      </button>
 *    </div>
 *  );
 * 
 */
interface MaintenanceState {
    isMaintenanceMode: boolean;
    setMaintenanceMode: (isMaintenanceMode: boolean) => void;
}

const useMaintenanceStore = create<MaintenanceState>()(
    devtools(
        persist(
            (set) => ({
                isMaintenanceMode: false, // default value
                setMaintenanceMode: (isMaintenanceMode: boolean) => {
                    set({ isMaintenanceMode });
                },
            }),
            {
                name: 'maintenanceStore', // key for the item in localStorage
                storage: createJSONStorage(() => localStorage),
            }
        )
    )
);

export const useIsMaintenanceMode = (): boolean => {
    return useMaintenanceStore((state) => state.isMaintenanceMode);
};

export default useMaintenanceStore;
