import { ReportCategory } from 'interface/ReportCategory'
import React from 'react'
import styles from 'styles/layout/ReportCategoriesHeaderSection.module.sass'
import HeaderNavigation from '../header/HeaderNavigation'
import ReportCategoryHero from './ReportCategoryHero'
import ReportCategoryCarousel from './ReportCategoryCarousel'

interface ReportCategoriesHeaderSectionProps {
    categories: ReportCategory[]
}

/**
 * ReportCategoriesHeaderSection component renders the header section for report categories, including navigation,
 * a hero section, and a carousel displaying hot reports.
 *
 * @component
 * @param {ReportCategoriesHeaderSectionProps} props - Props containing an array of report categories.
 *
 * @typedef {Object} ReportCategoriesHeaderSectionProps
 * @property {ReportCategory[]} categories - Array of report categories to be displayed in the header.
 *
 * @example
 * // Example usage:
 * import ReportCategoriesHeaderSection from './ReportCategoriesHeaderSection';
 *
 * const categories = [
 *   { id: '1', title: 'Market Insights', meta: { cover: 'image.jpg' }, description: 'Details about markets', topics: [] }
 * ];
 *
 * <ReportCategoriesHeaderSection categories={categories} />;
 *
 * @returns {JSX.Element} The rendered ReportCategoriesHeaderSection component.
 */
const ReportCategoriesHeaderSection: React.FC<ReportCategoriesHeaderSectionProps> = ({ categories }) => {

    return (
        <section className={styles['report-categories-header-section']}>
            <div className={styles['main-wrapper']}>
                <HeaderNavigation theme='dark' />
                <div className={styles['background-container']}>
                    <ReportCategoryCarousel />
                    <ReportCategoryHero categories={categories} />
                </div>
            </div>
        </section>
    )
}

export default ReportCategoriesHeaderSection;