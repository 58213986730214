import endpoints from "constants/api/Endpoints";
import { logError } from "services/logService/errorLogger";

/**
 * Fetches the maintenance status.
 *
 * @async
 * @param {string} tag The tag for the endpoint.
 * @returns {Promise<MaintenanceResponse | null>} A promise that resolves to the maintenance status or null.
 * @throws Logs an error if the fetch operation fails.
 * 
 * @example
 * const maintenanceStatus = await systemUnderMaintenance('Maintenance');
 * console.log('Maintenance status:', maintenanceStatus);
 */
export async function systemUnderMaintenance(tag: string): Promise<boolean> {
    try {
        const result = await endpoints.fetchMaintenanceStatus({ tag });
        if (result && result.success && result.data) {
            return result.data.isUnderMaintenance;
        }
        logError('Failed to fetch maintenance status', { error: result.error }, tag);
        return false
    } catch (error) {
        logError('Error fetching maintenance status', { error }, tag);
        return false;
    }
}