import EndpointProps from "interface/EndpointProps";
import Industry from "interface/Industry";
import { fetchApiData } from "../ApiHelper";
import { get, post, remove } from "../ApiClient";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";

/**
 * Get all industries from the database.
 *
 * This function retrieves all industries from the database.
 *
 * @function
 * @param {EndpointProps} props - The properties for the endpoint.
 * @param {string} props.url - The URL for the endpoint.
 * @param {string} props.method - The HTTP method for the endpoint.
 * @param {string} props.tag - The tag for the endpoint.
 *
 * @returns {Promise<Industry[]>} The list of industries from the database.
 */
export const getAllIndustries = async ({ tag }: EndpointProps): Promise<Industry[]> => {
    const result = await fetchApiData<DefaultApiResponse<Industry[]>>(
        tag,
        () => get<DefaultApiResponse<Industry[]>>({ tag, url: '/industries' }),
        (responseData) => responseData.data ?? []
    )

    return result.response.status === "success" ? result.response.data as Industry[] : []
}

/**
 * Remove an industry from the database.
 * 
 * This function removes an industry from the database.
 * 
 * @param {EndpointProps} props - The properties for the endpoint.
 * @param {string} props.tag - The tag for the endpoint.
 * @param {string} props.id - The ID of the industry to remove.
 * 
 * @return {Promise<{ success: boolean, message: string }>} The result of the operation.
 **/
export const removeInterestedIndustry = async ({ tag, id }: EndpointProps & { id: string }): Promise<{ success: boolean, message: string }> => {
    const result = await fetchApiData<DefaultApiResponse>(
        tag,
        () => remove<DefaultApiResponse>({ tag, url: `/industries/interest/${id}` }),
        (responseData) => responseData.data ?? null
    )

    return {
        success: result.response.status === "success",
        message: result.response.message ?? ""
    }
}

/**
 * Add an industry to the database.
 * 
 * This function adds an industry to the database.
 * 
 * @param {EndpointProps} props - The properties for the endpoint.
 * @param {string} props.tag - The tag for the endpoint.
 * @param {string[]} props.industries - The industries to add.
 * 
 * @return {Promise<{ success: boolean, message: string }>} The result of the operation.
 **/
export const addInterestedIndustries = async ({ tag, industries }: EndpointProps & { industries: string[] }): Promise<{ success: boolean, message: string }> => {
    if (!industries || industries.length === 0) {
        return {
            success: false,
            message: "Invalid industries data."
        }
    }

    const result = await fetchApiData<DefaultApiResponse>(
        tag,
        () => post<DefaultApiResponse>({ tag, url: `/industries/interest/add`, body: { industries } }),
        (responseData) => responseData.data ?? null
    )

    return {
        success: result.response.status === "success",
        message: result.response.message ?? ""
    }
}