import React, { useState }from 'react'
import styles from 'styles/components/SalesSolutionCard.module.sass'
import emailUsIcon from 'assets/images/svg/email-us.svg';
import PrimaryButton from 'components/common/PrimaryButton';
import Modal from 'components/common/Modal';
import ContactForm from 'components/forms/ContactForm';

/**
 * Props for the SalesSolutionCard component.
 */
interface SalesSolutionCardProps {
    openingText: string;
    mainTitle: string;
    descriptionText?: string;
    bannerImage: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

/**
 * SalesSolutionCard component displays a promotional section
 * encouraging users to contact sales if they cannot find a suitable plan.
 *
 * @component
 * 
 * @param {SalesSolutionCardProps} props - The props for the component.
 *
 * @example
 * // Renders the SalesSolutionCard component
 * <SalesSolutionCard
 *   openingText="WE ARE OPEN TO TALK"
 *   mainTitle="Can’t find the perfect plan?"
 *   descriptionText="Reach out to us with your exact requirements so that we can build a custom solution for your special needs."
 *   bannerImage="/path/to/image.png"
 *   buttonText="Talk to sales"
 *   onButtonClick={() => console.log('Button clicked')}
 * />
 *
 * @see PrimaryButton - A button component that triggers a contact form.
 */
const SalesSolutionCard: React.FC<SalesSolutionCardProps> = ({
    openingText,
    mainTitle,
    descriptionText,
    bannerImage = emailUsIcon,
    buttonText = 'Talk to Sales',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

    return (
        <div className={styles['sales-solution-card']}>
            <div className={styles['left-wrapper']}>
                <div className={styles['text-wrapper']}>
                    <p className={styles['opening-text']}>{openingText}</p>
                    <p className={styles['main-title']}>{mainTitle}</p>
                    {descriptionText && (
                        <p className={styles['description-text']}>{descriptionText}</p>
                    )}
                </div>
            
                <PrimaryButton
                        text={buttonText}
                        primaryColor="#ff5522"
                        textColor="#fff"
                        hoverColor="#fff"
                        hoverTextColor="#ff5522"
                        doSomething={handleButtonClick}
                />
            </div>
            <div className={styles['right-wrapper']}>
                <img src={bannerImage} className={styles['banner-image']} />
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ContactForm
                    showCloseButton={true}
                    onClose={() => setIsModalOpen(false)} 
                />
            </Modal>
        </div>
    )
}

export default SalesSolutionCard;