import React, { useState } from 'react'
import styles from 'styles/components/ReportPaymentForm.module.sass'
import ZeroneLoader from 'components/common/ZeroneLoader'
import StripePaymentForm from './StripePaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import { useToast } from 'provider/ToastProvider'
//import stripeErrorMessages from 'constants/data/StripeErrors'
import { logError } from 'services/logService/errorLogger'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import StripePaymentIntentResult from 'interface/StripePaymentIntentResult'
import PrimaryButton from 'components/common/PrimaryButton'
import { validateAndSanitizeReportPurchaseUpdate } from 'utils/validateAndSanitizeReportPurchaseUpdate'
import { updateReportPurchaseStatus } from 'api/ReportApiService'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
const currency = 'USD'
const MIN_VALID_AMOUNT: number = 5.5
const TAG = 'ReportPaymentForm'

const ReportPaymentForm: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<string>('')
    const { addToast } = useToast()
    const location = useLocation()
    const [url, setUrl] = useState<string>('')
    const navigate = useNavigate()
    
    const { paymentIntent, title } = location.state as { paymentIntent: StripePaymentIntentResult, title: string } || { paymentIntent: null, title: '' }
    const { id } = useParams<{ id: string }>()

    if (!id) {
        logError('No report ID found.', {}, TAG)
        addToast({
            message: 'Oops! We encountered an issue processing your payment. Please try again or contact support.',
            type: 'error'
        })
        window.location.href = '/report/categories'
        return null
    }

    if (!paymentIntent) {
        logError('No payment intent found.', {}, TAG)
        addToast({
            message: 'Oops! We encountered an issue processing your payment. Please try again or contact support.',
            type: 'error'
        })
        window.location.href = '/report/categories'
        return null
    }

    if (paymentIntent && paymentIntent.amount < MIN_VALID_AMOUNT) {
        logError(`Invalid payment amount: ${paymentIntent.amount}.`, { paymentIntent }, TAG)
        setMessage('The payment amount is invalid. Please contact support for assistance.')
        addToast({
            message: 'Something went wrong. Please check your details and try again.',
            type: 'error'
        })
        window.location.href = '/contact-support'
        return null
    }

    const updatePaymentStatus = async (paymentId: string, status: 'failed' | 'paid' | 'cancelled') => {
        setMessage('Processing payment status update...')
        setLoading(true)

        try {
            const sanitizedData = validateAndSanitizeReportPurchaseUpdate({ paymentId, status })
            if (!sanitizedData) {
                logError('Failed to sanitize report purchase update data.', { paymentId, status }, TAG)
                addToast({
                    message: 'Failed to process your payment. Please try again or contact support.',
                    type: 'error'
                })
                return
            }

            const result = await updateReportPurchaseStatus({
                tag: TAG,
                id,
                data: {
                    status: sanitizedData.status as 'failed' | 'paid',
                    paymentId
                }
            })
            if (result && result.success && status === 'paid') {
                if (result.url && result.url.trim() !== '') {
                    setUrl(result.url)
                    addToast({
                        message: `Payment successful for ${currency} ${paymentIntent.amount}!`,
                        type: 'success'
                    })
                    setMessage('Payment successful. Download your report below.')
                } else {
                    logError('No download URL provided.', { paymentId, status }, TAG)
                    addToast({
                        message: 'Payment successful, we are processing your payment. An email will be sent to you with the download link.',
                        type: 'warning'
                    })
                    navigate(`/report/${id}`)
                }
            } else if (!result.success && status === 'paid') {
                logError('Failed to update report purchase status.', { paymentId, status }, TAG)
                addToast({
                    message: 'We are processing your payment. An email will be sent to you with the download link.',
                    type: 'warning'
                })
                navigate(`/report/${id}`)
            }
        } catch (error) {
            logError('Error updating payment status.', { error }, TAG)
            addToast({
                message: 'An error occurred while processing your payment. Please try again or contact support.',
                type: 'error'
            })
            navigate(`/report/${id}`)
        } finally {
            setLoading(false)
        }
    }

    const onPaymentSuccess = (paymentId: string) => {
        setMessage('Payment successful. Download your report below.')
        updatePaymentStatus(paymentId, 'paid').catch((error) => {
            logError('Error processing payment success.', { error }, TAG)
            addToast({
                message: 'We are processing your payment. An email will be sent to you with the download link.',
                type: 'error'
            })
            navigate(`/report/${id}`)
        })
    }

    const onPaymentFailure = (errorMessage: string) => {
        setLoading(false)
        setMessage('Payment failed. Please try again.')
        logError(errorMessage, {}, TAG)
        addToast({
            message: 'Payment failed. Please try again or contact support.',
            type: 'error'
        })
    }

    const onCancelPayment = () => {
        setLoading(false)
        setMessage('Payment cancelled.')
        logError('Payment cancelled.', {}, TAG)
        addToast({
            message: 'Payment cancelled.',
            type: 'warning'
        })
        navigate('/report/categories')
    }

    const handleDownloadClicked = () => {
        if (url && url.trim() !== '') {
            window.open(url, '_blank')
        }
        setTimeout(() => {
            navigate('/report/categories')
        }, 3000)
    }

    return (
        <div className={styles['report-payment-form']}>
            <div className={styles['header-container']}>  
                <div className={styles['logo-container']}>
                    <Link to="/">
                        <img src={zeroneLogoDark} className={styles['zerone-logo']} alt="Zerone AnalytiQs Logo" />
                    </Link>
                    <h2 className={styles['form-title']}>{title ? `${title}` : 'Buy Report'}</h2>
                </div>
            </div>
            {loading ? (
                <div className={styles['loader']}>
                    <ZeroneLoader text={message} />
                </div>
            ) : paymentIntent.clientSecret && message.trim() === '' ? (
                <div className={styles['main-wrapper']}>
                    <div className={styles['summary-section']}>   
                        <p className={styles['summary-title']}>{`Complete your one-time secure payment to access this report. The download link will be provided after payment.`}</p>
                        <div className={styles['right']}>
                            <p className={styles['amount']}>{currency} {isNaN(paymentIntent.amount) ? '0.00': paymentIntent.amount.toFixed(2) || '0.00'}</p>
                        </div>
                    </div>
                    <div className={styles['divider']} />
                    <div className={styles['payment-section']}>
                        <Elements stripe={stripePromise}>
                            <StripePaymentForm
                                clientSecret={paymentIntent.clientSecret}
                                totalAmount={paymentIntent.amount}
                                showInfo={false}
                                regularPayment={true}
                                onPaymentSuccess={onPaymentSuccess}
                                onPaymentFailure={onPaymentFailure}
                                onCancelPayment={onCancelPayment}
                            />
                        </Elements>
                    </div>
                </div>
            ) : (
                <div className={styles['message-section']}>
                    <p className={styles['message']}>{message}</p>
                    {message.toLowerCase().includes('download') && (            
                        <PrimaryButton
                            text='Download Report'
                            primaryColor='#FF5522'
                            textColor='#FFFFFF'
                            hoverColor='#FFFFFF'
                            hoverTextColor='#FF5522'
                            doSomething={handleDownloadClicked}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default ReportPaymentForm;