import React from 'react'
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer'
import styles from 'styles/pages/PaymentPage.module.sass'
import SubscriptionPaymentForm from 'components/forms/SubscriptionPaymentForm'

/**
 * SubscriptionPaymentPage component for rendering the subscription payment process.
 * It serves as the main entry point for users to enter their payment information
 * during the subscription process.
 *
 * @component
 * 
 * @example
 * // Render the SubscriptionPaymentPage
 * <SubscriptionPaymentPage />
 * 
 * @see AnimatedBackgroundContainer - Component providing an animated background and optional footer.
 * @see SubscriptionPaymentForm - The form component for handling user payment input during subscription.
 */
const SubscriptionPaymentPage: React.FC = () => {
    return (
        <div className={styles['payment-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <SubscriptionPaymentForm />
            </AnimatedBackgroundContainer>
        </div>
    )
}

export default SubscriptionPaymentPage;