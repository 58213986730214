import React from 'react'
import styles from 'styles/layout/ReportCategoryItem.module.sass'
import ReportCategoryHeader from './ReportCategoryHeader'
import TopicCard from 'components/cards/TopicCard'
import { ReportCategory } from 'interface/ReportCategory'

/**
 * ReportCategoryItem component displays a report category with a header, description, and a list of related topics.
 *
 * @component
 * @param {ReportCategory} props - The properties for the report category.
 * @param {UUID} props.id - Unique identifier for the report category.
 * @param {string} props.title - The title of the report category.
 * @param {string} props.meta - Additional meta information about the report category.
 * @param {string} props.description - A brief description of the report category.
 * @param {Array<{ id: UUID, title: string, year: string }>} props.topics - An array of topics related to the report category, each topic containing an ID, title, and year.
 *
 * @example
 * <ReportCategoryItem 
 *   id="fabd37f0-7bd9-413a-b782-cbc73fb01b00" 
 *   title="Technology Trends" 
 *   meta="Updated monthly" 
 *   description="Explore the latest technology trends in various industries." 
 *   topics={[
 *     { id: "topic1", title: "AI Advancements", year: "2024" },
 *     { id: "topic2", title: "Blockchain Innovations", year: "2024" }
 *   ]}
 * />
 *
 * @returns {JSX.Element} A component that renders the report category with its header, description, and list of topic cards.
 */
const ReportCategoryItem: React.FC<ReportCategory> = ({
    id,
    title,
    meta,
    description,
    topics
}) => {
    return (
        <div className={styles['report-category']}>
            <ReportCategoryHeader 
                id={id} 
                title={title} 
                meta={meta} 
            />
            <div className={styles['category-body']}>
                <p className={styles['description']}>{description}</p>
                <div className={styles['top-categories-wrapper']}>
                    {topics && topics.map((topic) => (
                        <TopicCard
                            key={topic.id}
                            id={topic.id}
                            highlight={topic.dataset || ''}
                            title={topic.title}
                            year={topic.year}
                            cover={topic.meta.cover || ''}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ReportCategoryItem