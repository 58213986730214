import React from 'react';
import styles from 'styles/components/Modal.module.sass';

/**
 * Interface defining the props for the Modal component.
 * 
 * @param isOpen - A boolean indicating whether the modal should be visible.
 * @param onClose - A callback function to handle closing the modal.
 * @param children - The content to be rendered inside the modal.
 */
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

/**
 * A reusable Modal component that displays its content in a centered overlay.
 * 
 * @param props - The props object containing isOpen, onClose, and children.
 */
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']} onClick={onClose}>
      <div className={styles['modal-content']} onClick={(e) => e.stopPropagation()}>
        <div className={styles['modal-header']}>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
