import { get } from "../ApiClient";
import EndpointProps from "interface/EndpointProps";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import { fetchApiData } from "../ApiHelper";
import { MaintenanceResponse } from "interface/MaintenanceResponse";

/**
 * Fetches the maintenance status of the application.
 *
 * @param {EndpointProps} params - The parameters for the API request.
 * @returns {Promise<{ success: boolean; data: MaintenanceResponse | null; error: string | null }>} A promise that resolves to an object containing the maintenance status or an error message.
 */
export async function fetchMaintenanceStatus({ tag }: EndpointProps): Promise<{ success: boolean; data: MaintenanceResponse | null; error: string | null }> {
    const result = await fetchApiData<DefaultApiResponse<MaintenanceResponse>>(
        tag,
        () => get<DefaultApiResponse<MaintenanceResponse>>({ tag, url: "/maintenance" }),
        (responseData) => responseData.data ?? null
    );

    return {
        success: result.response.status === "success",
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.message : null,
    };
}