import endpoints from "constants/api/Endpoints"
import { zeroneReportCategories } from "constants/data/report/ZeroneReportCategories"
import { CategoryTopic } from "interface/CategoryTopic"
import FetchDataByIDProps from "interface/FetchDataByIDProps"
import ReportAccess from "interface/ReportAccess"
import ReportCatalog from "interface/ReportCatalog"
import { ReportCategory } from "interface/ReportCategory"
import StripePaymentIntentResult from "interface/StripePaymentIntentResult"
import ZeroneReport from "interface/ZeroneReport"
import { logError } from "services/logService/errorLogger"
import logInfo from "services/logService/infoLogger"

/**
 * Fetches report categories from the API or returns default categories if an error occurs.
 * 
 * @async
 * @function fetchCategories
 * @param {string} tag - A tag used for logging and identifying the request.
 * @returns {Promise<ReportCategory[]>} - A promise that resolves to an array of report categories.
 * 
 * @example
 * async function loadCategories() {
 *   const categories = await fetchCategories('CategoryFetch');
 *   console.log('Fetched categories:', categories);
 * }
 */
export async function fetchCategories(tag: string): Promise<ReportCategory[]>{
    try {
        const result = await endpoints.getReportCategories({ tag })
        if (result.error) {
            logError('Error fetching report categories.', { result }, tag)
            return defaultCategories()
        }
        return result.data ? result.data : defaultCategories()
    } catch (error) {
        logError(
            'Unable to retrieve report categories.',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultCategories()
    }
}

/**
 * Fetches top hot reports (trending reports) from the API or returns default trending reports if an error occurs.
 * 
 * @async
 * @function fetchTopHotReports
 * @param {string} tag - A tag used for logging and identifying the request.
 * @returns {Promise<CategoryTopic[]>} - A promise that resolves to an array of trending reports.
 * 
 * @example
 * async function loadHotReports() {
 *   const hotReports = await fetchTopHotReports('HotReportFetch');
 *   console.log('Fetched trending reports:', hotReports);
 * }
 */
export async function fetchTopHotReports(tag: string): Promise<CategoryTopic[]>{
    try {
        const result = await endpoints.getTopHotReports({ tag })
        if (result.error) {
            logError('Error fetching trending reports.', { result }, tag)
            return []
        }
        return result.data ? result.data : []
    } catch (error) {
        logError(
            'Unable to retrieve trending reports.',
            { error: error  instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return []
    } 
}

/**
 * Fetches a specific report by its ID from the API or returns a default report if an error occurs.
 * 
 * @async
 * @function fetchReport
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to fetch.
 * @returns {Promise<ReportContent | null>} - A promise that resolves to the fetched report content or `null` if not found.
 * 
 * @example
 * async function loadReport() {
 *   const report = await fetchReport({ tag: 'ReportFetch', id: '12345' });
 *   console.log('Fetched report:', report);
 * }
 */
export async function fetchReport({ tag, id }: FetchDataByIDProps): Promise<ZeroneReport | null> {
    if (!id) {
        logError('Invalid ID provided', { id }, tag)
        return null
    }
    try {
        const result = await endpoints.getReport({ tag, id })
        if (result.success && result.data) {
            return result.data
        }
        logError('Error fetching report.', { id, result }, tag)
        return null;
    } catch (error) {
        logError(
            'Unable to retrieve report.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return null;
    }
}

/**
 * Fetches a list of reports from the API based on a specific tag.
 * 
 * @async
 * @function fetchReportCatalog
 * @param {string} tag - A tag used for logging and identifying the request.
 * @returns {Promise<ReportCatalog[]>} - A promise that resolves to an array of report catalogs.
 * 
 * @example
 * async function loadReportCatalog() {
 *   const reportCatalog = await fetchReportCatalog('ReportCatalogFetch');
 *   console.log('Fetched report catalog:', reportCatalog);
 * }
 */
export async function fetchReportCatalog(tag: string): Promise<ReportCatalog[]> {
    try {
        const result = await endpoints.getReportCatalog({ tag })
        if (result.error) {
            logError('Error fetching report catalog.', { result }, tag)
            return []
        }
        return result.data ? result.data : []
    } catch (error) {
        logError(
            'Unable to retrieve report catalog.',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return []
    }
}

/**
 * Checks if the user has access to a specific report by its ID.
 * 
 * @async
 * @function checkAccessToReport
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to check.
 * @returns {Promise<ReportAccess>} - A promise that resolves to an object containing access information.
 * 
 * @example
 * async function checkAccess() {
 *   const access = await checkAccessToReport({ tag: 'ReportAccess', id: '12345' });
 *   console.log('Access to report:', access);
 * }
 */
export async function checkAccessToReport({ tag, id }: FetchDataByIDProps): Promise<ReportAccess> {
    try {
        const result = await endpoints.checkAccessToReport({ tag, id })
        logInfo('Checking access to report.', { id, result }, tag)
        return result.success && result.data ? result.data : { hasAccess: false, ableToPurchase: false } 
    } catch (error) {
        logError(
            'Unable to check access to report.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return { hasAccess: false, ableToPurchase: false }
    }
}

/**
 * Downloads a specific report by its ID from the API or returns null if an error occurs.
 * 
 * @async
 * @function downloadReport
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to download.
 * @returns {Promise<string | null>} - A promise that resolves to the downloaded report content or `null` if not found.
 * 
 * @example
 * async function download() {
 *   const reportContent = await downloadReport({ tag: 'ReportDownload', id: '12345' });
 *   console.log('Downloaded report content:', reportContent);
 * }
 */
export async function downloadReport({ tag, id }: FetchDataByIDProps): Promise<string | null> {
    try {
        const result = await endpoints.downloadReport({ tag, id })
        if (result.success && result.data && typeof result.data.url === 'string' && result.data.url.length > 0) {
            return result.data.url
        }
        logError('Error downloading report.', { id, result }, tag)
        return null
    } catch (error) {
        logError(
            'Unable to download report.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return null
    }
}

/**
 * Purchases a specific report using credits from the API.
 * 
 * @async
 * @function purchaseReportUsingCredits
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to purchase.
 * @returns {Promise<{ success: boolean, message: string; creditsUsed: number; url: string }>} - A promise that resolves to an object containing purchase information.
 * 
 * @example
 * async function purchase() {
 *   const purchaseInfo = await purchaseReportUsingCredits({ tag: 'ReportPurchase', id: '12345' });
 *   console.log('Purchase information:', purchaseInfo);
 * }
 */
export async function purchaseReportUsingCredits({ tag, id }: FetchDataByIDProps): Promise<{ success: boolean, message: string; creditsUsed: number; url: string }>{
    try {
        const result = await endpoints.purchaseReportUsingCredits({ tag, id })
        if (result.success && result.data) {
            return { success: true, message: 'Report purchased successfully.', creditsUsed: result.data.creditsUsed, url: result.data.url }
        }

        logError('Error purchasing report.', { id, result }, tag)
        return { success: false, message: 'Error purchasing report.', creditsUsed: 0, url: '' }
    } catch (error) {
        logError(
            'Unable to purchase report.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return { success: false, message: 'Error purchasing report.', creditsUsed: 0, url: '' }
    }
}

/**
 * Purchases a specific report using payment from the API.
 * 
 * @async
 * @function purchaseReportUsingPayment
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to purchase.
 * @returns {Promise<ReportPurchaseDefaultApiResponse>} - A promise that resolves to an object containing purchase information.
 * 
 * @example
 * async function purchase() {
 *   const purchaseInfo = await purchaseReportUsingPayment({ tag: 'ReportPurchase', id: '12345' });
 *   console.log('Purchase information:', purchaseInfo);
 * }
 */
export async function getReportPurchaseIntent({ tag, id }: FetchDataByIDProps): Promise<{ success: boolean, message: string; hasAccess: boolean, url: string, paymentIntent: StripePaymentIntentResult}>{
    try {
        const result = await endpoints.purchaseReportUsingPayment({ tag, id })
        if (result && result.success && result.data) {
            const { data } = result;
            if ('amount' in data && 'clientSecret' in data && 'metadata' in data) {
                return { success: true, message: 'Report purchase link generated.', hasAccess: false, url: '', paymentIntent: data };
            } else if ('creditsUsed' in data && 'url' in data) {
                return { success: true, message: 'Report purchase link generated.', hasAccess: true, url: data.url, paymentIntent: { amount: 0, clientSecret: '', metadata: {} } };
            } else {
                logError('Error fetching report purchase intent.', { id, result }, tag);
                return { success: false, message: 'Error fetching report purchase intent.', hasAccess: false, url: '', paymentIntent: { amount: 0, clientSecret: '', metadata: {} } };
            }
        }
        logError('Error fetching report purchase intent.', { id, result }, tag)
        return { success: false, message: 'Error fetching report purchase intent.', hasAccess: false, url: '', paymentIntent: { amount: 0, clientSecret: '', metadata: {} } }
    } catch (error) {
        logError(
            'Unable to fetch report purchase intent.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return { success: false, message: 'Error fetching report purchase intent.', hasAccess: false, url: '', paymentIntent: { amount: 0, clientSecret: '', metadata: {} } }
    }
}

/**
 * Updates the purchase status of a report.
 * 
 * @async
 * @function updateReportPurchaseStatus
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to update.
 * @returns {Promise<{ success: boolean, message: string }>} - A promise that resolves to an object containing the update status.
 * 
 * @example
 * async function updateStatus() {
 *   const updateInfo = await updateReportPurchaseStatus({ tag: 'ReportUpdate', id: '12345' });
 *   console.log('Update information:', updateInfo);
 * }
 */
export async function updateReportPurchaseStatus({ tag, id, data }: FetchDataByIDProps & { data: {
    status: 'paid' | 'failed',
    paymentId: string
}}): Promise<{ success: boolean, message: string, url?: string }> {
    try {
        const result = await endpoints.updateReportPurchaseStatus({ tag, id, data })
        if (result.success && result.data) {
            return { success: true, message: 'Report purchase status updated successfully.', url: result.data.url? result.data.url : '' }
        }
        logError('Error updating report purchase status.', { id, result }, tag)
        return { success: false, message: 'Error updating report purchase status.' }
    } catch (error) {
        logError(
            'Unable to update report purchase status.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return { success: false, message: 'Error updating report purchase status.' }
    }
}

/**
 * Provides a default set of report categories for use when fetching categories fails.
 * 
 * @function defaultCategories
 * @returns {ReportCategory[]} - An array of default report categories.
 */
function defaultCategories(): ReportCategory[] {
    return zeroneReportCategories;
}

/**
 * Provides a default set of trending reports for use when fetching hot reports fails.
 * 
 * @function defaultTrendingReports
 * @returns {CategoryTopic[]} - An array of default trending reports.
 */
// function defaultTrendingReports(): CategoryTopic[] {
//     return [
//         {
//             id: "05c1ba4d-552d-4dc1-bee0-eba6b68a7cc4",
//             title: "GHANA'S DOMESTIC AIRLINE MARKET",
//             description: "In this report we present analysis, findings and insights on the domestic commercial airline market in Ghana. It contains a detailed review of the competitive landscape including who the major players are, market shares and competition strategies. We project key metrics such as revenue passenger miles (RPM), Available Seat Miles (ASM), Passenger load factor, etc. The report also contains customer preference, insights and sentiments on specific airlines and the industry as a whole.",
//             dataset: "Markets, Industries & Sectors",
//             year: "2024",
//             meta: {
//                 cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FGHANAS%20DOMESTIC%20AIRLINE%20MARKET%2Fdomestic-airline.jpg?alt=media&token=ca757880-9663-4801-b40a-100852be2120'
//             },
//             category: ""
//         },
//         {
//             id: "5740dba9-85e5-4286-9f00-0307ed841b51",
//             title: "SHORT-STAY RENTAL IN KENYA",
//             description: "Discover how players in Kenya’s short-term hospitability sector are pricing their units and what features have the biggest sway on guests. This report contains analysis of several Airbnb listings in Kenya in 2024. It assesses which neighborhoods command premium pricing, the effects of guest ratings/reviews on success and analyzes the impact of hosts gender, etc.",
//             dataset: "Countries & Economies",
//             year: "2024",
//             meta: {
//                 cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FSHORT-STAY%20RENTAL%20IN%20KENYA%2Fshort-stay-rental-kenya.jpg?alt=media&token=13260ce0-a94c-45df-a995-60e27d78eb99'
//             },
//             category: ""
//         },
//         {
//             id: "d6c92e98-25a8-491a-aeb3-4dd247bd18e3",
//             title: "AFRICA'S DIGITIZATION FOOTPRINT",
//             description: 'Dive into our "State of Data In Africa" report and discover how the global digital revolution is playing out in the African context. This report contains an analysis of Africa’s data landscape, including the rise in data centers, government spending on digitization and private sector initiatives, among others. It discusses data gaps in African and opportunities available in the market for investors, entrepreneurs and other players.',
//             dataset: "Markets, Industries & Sectors",
//             year: "2024",
//             meta: {
//                 cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
//             },
//             category: ""
//         }
//     ]
// }

// function defaultReport(): ReportContent {
//     return AfricasDigitizationFootPrint;
// }