import React from "react";
import styles from 'styles/components/ReportSummaryBody.module.sass';
import { TableOfContents } from "interface/ReportContent";
import { BetaChartData } from "interface/BetaChartData";
import InteractiveChart from "./InteractiveChart";

/**
 * Props for the ReportSummaryBody component.
 * 
 * @interface ReportSummaryBodyProps
 * @property {TableOfContents[]} tableOfContents - An array of TableOfContents objects.
 */
interface ReportSummaryBodyProps {
    tableOfContents: TableOfContents[];
    showcase?: BetaChartData | null;
    hasAccessToReport: boolean;
}

/**
 * ReportSummaryBody component displays a summary of the report including a table of contents.
 *
 * @component
 * @param {ReportSummaryBodyProps} props - The props for the component.
 * @param {Array<{title: string, description: string}>} props.tableOfContents - The table of contents data to be displayed.
 * @returns {JSX.Element} The rendered ReportSummaryBody component.
 */
const ReportSummaryBody: React.FC<ReportSummaryBodyProps> = ({ tableOfContents, showcase, hasAccessToReport }) => {

    const renderInteractiveChart = (chartData: BetaChartData) => {
        return (
            <InteractiveChart
                key={chartData.title}
                chartType={chartData.chartType}
                title={chartData.title}
                labels={chartData.labels}
                values={chartData.values}
                {...(chartData.groups ? { groups: chartData.groups } : {})}
                xLabel={chartData.xLabel ? chartData.xLabel : ''}
                yLabel={chartData.yLabel ? chartData.yLabel : ''}
            />
        );
    }

    return (
        <div className={styles['report-summary-body']}>
            {tableOfContents && tableOfContents.length > 0 && (
                <div className={styles['toc']}>
                    <h2 className={styles['title']}>Table of Contents</h2>
                    <div className={styles['toc-list']}>
                        {tableOfContents.map((toc, index) => (
                            <div key={index} className={styles['toc-item']}>
                                <p className={styles['toc-item-heading']}>{toc.title}</p>
                                {toc.description && toc.description.split('\n').map((desc, i) => (
                                    <p key={i} className={styles['toc-item-subheading']}>{`• ${desc}`}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            {showcase && (
                <div className={styles['showcase']}>
                    <div className={styles['showcase-content']}>
                        <div className={styles['blurb']} style={{ filter: hasAccessToReport ? 'none' : 'blur(5px)'}}>
                            {renderInteractiveChart(showcase)}
                        </div>
                    </div>
                        
                    <p className={styles['showcase-note']} style={{ display : hasAccessToReport ? 'none' : 'block' }}>
                        {hasAccessToReport ? 'View the full report for more insights.' : 'Unlock the full report to view more insights.'}
                    </p>
                </div>
            )}
        </div>
    );
}

export default ReportSummaryBody