import SalesSolutionCard from 'components/cards/SalesSolutionCard';
import React from 'react'
import styles from 'styles/layout/ContactSalesSection.module.sass'
import loudSpeaker from 'assets/images/png/loud-speaker.png'

/**
 * ContactSalesSection component displays a section with a title, subtitle,
 * and a SalesSolutionCard component to encourage users to contact sales for custom solutions.
 *
 * @component
 *
 * @example
 * // Renders the ContactSalesSection component
 * <ContactSalesSection />
 *
 * @see SalesSolutionCard - Component for displaying contact sales call-to-action.
 */
const AboutContactSalesSection: React.FC = () => {
    return (
        <section className={styles['contact-sales-section']}>
            <SalesSolutionCard 
                openingText={'COME WORK WITH US'} 
                mainTitle={'Interested in working with our growing team of passionate data analysts, engineers, editorial team and market research?'}
                buttonText={'Join the Team'}
                bannerImage={loudSpeaker}
            />
        </section>
    )
}

export default AboutContactSalesSection;