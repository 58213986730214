import ChangePlan from "interface/ChangePlan";
import { SignUpResponse } from "../models/SignUpResponse";
import { fetchApiData } from "../ApiHelper";
import EndpointProps from "interface/EndpointProps";
import { get, post } from "../ApiClient";
import UpdatePayment from "interface/UpdatePayment";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import UserSubscription from "interface/UserSubscription";
import SubscriptionInvoice from "interface/SubscriptionInvoice";
import StripePaymentIntentResult from "interface/StripePaymentIntentResult";

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/**
 * Updates the subscription plan for a user.
 *
 * @param {Object} params - The parameters for updating the subscription plan.
 * @param {string} params.tag - The tag associated with the endpoint.
 * @param {ChangePlan} params.data - The data payload containing the new subscription plan details.
 * @returns {Promise<{ data: SignUpResponse | null; error: string | null }>} A promise that resolves to an object containing the response data or an error message.
 *
 * @throws {Error} Throws an error if the fetch operation fails.
 */
export async function updateSubscriptionPlan({
    tag,
    data,
}: EndpointProps & { data: ChangePlan }): Promise<{ data: SignUpResponse | null; error: string | null }> {
    if (!data) {
        return {
            data: null,
            error: "Invalid change plan request: Missing data payload.",
        };
    }

    const result = await fetchApiData<SignUpResponse>(
        tag,
        () =>
            post<SignUpResponse>({
                tag,
                url: "/subscription/change/plan",
                body: data,
            }),
        (responseData) => responseData
    );

    if (result.response.status === "error") {
        return {
            data: null,
            error: result.response.message || "An error occurred while updating plan.",
        };
    }

    return {
        ...result.response.data || null,
        error: null,
    };
}

/**
 * Updates the subscription payment information.
 *
 * @param {EndpointProps & { data: UpdatePayment }} param0 - The endpoint properties and payment update data.
 * @param {string} param0.tag - The tag for the endpoint.
 * @param {UpdatePayment} param0.data - The payment update data.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the update was successful.
 * @throws {Error} - Throws an error if the data payload is missing.
 */
export async function updateSubscriptionPayment({ tag, data }: EndpointProps & { data: UpdatePayment }): Promise<boolean> {
    if (!data) {
        throw new Error ('Invalid payment update request: Missing data payload.')
    }

    const result = await fetchApiData<DefaultApiResponse>(tag,
        () => post<DefaultApiResponse>({ tag, url: '/subscription/payment', body: data }),
        (responseData) => responseData
    )

    return result.response.status === 'success'
}

/**
 * Fetches subscription data from the API.
 *
 * @param {EndpointProps} param0 - The endpoint properties.
 * @param {string} param0.tag - The tag used for fetching the subscription data.
 * @returns {Promise<{ success: boolean; data?: UserSubscription }>} A promise that resolves to an object containing the success status and the subscription data.
 */
export async function fetchSubscriptionData({ tag }: EndpointProps): Promise<{ success: boolean; data?: UserSubscription }> {
    const result = await fetchApiData<DefaultApiResponse<UserSubscription>>(tag,
        () => get<DefaultApiResponse<UserSubscription>>({ tag, url: `/subscription`}),
        (responseData) => responseData.data
    )

    return {
        success: result.response.status === 'success',
        data: result.response.data
    }
}

/**
 * Fetches subscription invoices from the API.
 * This function fetches subscription invoices based on the specified status.
 * 
 * @param {EndpointProps & { status: 'succeeded' | 'processing' | 'canceled' | 'failed' }} param0 - The endpoint properties and invoice status.
 * @param {string} param0.tag - The tag used for fetching the subscription invoices.
 * @param {'succeeded' | 'processing' | 'canceled' | 'failed'} param0.status - The status of the invoices to fetch.
 * 
 * @returns {Promise<{ success: boolean; data?: SubscriptionInvoice[]; error?: string | null}>} A promise that resolves to an object containing the success status, the invoice data, and an error message if applicable.
 * 
 * @throws {Error} Throws an error if the fetch operation fails.
 * 
 * @example
 * const { success, data, error } = await getSubscriptionInvoices({ tag: 'SubscriptionInvoices', status: 'succeeded' })
 * if (success) {
 *    console.log('Invoices:', data)
 * } else {
 *   console.error('Failed to fetch invoices:', error)
 * }
 */
export async function getSubscriptionInvoices({ tag, status }: EndpointProps & { status: 'succeeded' | 'processing' | 'canceled' | 'failed' }): Promise<{ success: boolean; data?: SubscriptionInvoice[], error?: string | null}> {
    const result = await fetchApiData<DefaultApiResponse<SubscriptionInvoice[]>>(tag,
        () => get<DefaultApiResponse<SubscriptionInvoice[]>>({ tag, url: `/subscription/invoices?status=${status}`}),
        (responseData) => responseData.data ?? []
    )

    return {
        success: result.response.status === 'success',
        data: result.response.status === 'success' ? result.response.data : [],
        error: result.response.status === 'error' ? result.response.message : null
    }
}

/**
 * Checks out a subscription and returns the payment intent result.
 *
 * @param {EndpointProps} param0 - The endpoint properties.
 * @param {string} param0.tag - The tag used for fetching the subscription checkout.
 * @returns {Promise<{ success: boolean; data?: StripePaymentIntentResult | null; error?: string | null}>} A promise that resolves to an object containing the success status, payment intent result, and an error message if applicable.
 */
export async function checkOutSubscription({ tag}: EndpointProps): Promise<{ success: boolean; data?: StripePaymentIntentResult | null; error?: string | null}> {
    const result = await fetchApiData<DefaultApiResponse<StripePaymentIntentResult>>(tag,
        () => get<DefaultApiResponse<StripePaymentIntentResult>>({ tag, url: `/subscription/checkout`}),
        (responseData) => responseData.data ?? null
    )

    return {
        success: result.response.status === 'success',
        data: result.response.status === 'success' ? result.response.data : null,
        error: result.response.status === 'error' ? result.response.message : null
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */