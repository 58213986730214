import React, { useState }from 'react'
import styles from 'styles/components/EducationPricing.module.sass'
import { Icon } from '@iconify/react';
import checkIcon from '@iconify/icons-mdi/check-bold'
import { educationPlan } from 'constants/subscription/EducationPricePlan';
import SecondaryButton from 'components/common/SecondaryButton';
import educationImage from 'assets/images/svg/pricing-education.svg'
import ContactForm from 'components/forms/ContactForm';
import Modal from 'components/common/Modal';
/**
 * EducationPricing component presents the education-specific pricing plan with features and a contact button.
 * 
 * @component
 * 
 * @example
 * // Renders the EducationPricing component with a contact button that opens a contact form
 * <EducationPricing />
 */
const EducationPricing: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleButtonClick = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    return (
        <div className={styles['education-container']}>
            <div className='left-wrapper'>
                <p className={styles['heading']}>On Demand Pricing Available!</p>
                <ul className={styles['feature-list']}>
                    {educationPlan.map((feature, index) => (
                        <li key={index} className={styles['item']}>
                            <Icon icon={checkIcon} className={styles['check-icon']} />
                            {feature}
                        </li>
                    ))}
                </ul>
                <SecondaryButton
                    width='auto'
                    text="Contact us"
                    primaryColor="#FFFFFF"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={handleButtonClick}
                />
            </div>
            <div className={styles['right-wrapper']}>
                <img src={educationImage} />
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <ContactForm
                    showCloseButton={true}
                    onClose={() => setIsModalOpen(false)} 
                />
            </Modal>
        </div>
    )
}

export default EducationPricing;