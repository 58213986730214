import { logError } from "services/logService/errorLogger";
import endpoints from "constants/api/Endpoints";
import Inquiry from "interface/Inquiry";

const tag = 'ContactApiService'

/**
 * Submits a contact inquiry.
 *
 * @async
 * @param {Inquiry} inquiry - The contact inquiry data.
 * @returns {Promise<boolean>} A promise that resolves to true if the submission is successful, otherwise false.
 */
export const processSubmitContactInquiry = async (inquiry: Inquiry): Promise<boolean> => {
    try {
        if (!inquiry) {
            logError('Missing inquiry payload.', inquiry, tag)
            return false
        }

        const result = await endpoints.submitContactInquiry({ tag: tag, data: inquiry })
        return result
    } catch (error) {
        logError(
            'Error submitting contact inquiry',
            { error, inquiry },
            tag
        )
        return false
    }
}