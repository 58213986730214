import React, { useEffect, useState } from "react"
import styles from 'styles/components/ReportDetailsCard.module.sass'
import ReportDetails from "interface/ReportDetails"
import SearchDropdown from "components/common/SearchDropdown";
import { Option } from "interface/Option";
import PrimaryButton from "components/common/PrimaryButton";
import { useAuth } from "hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import ReusableIcon from "components/common/ReusableIcon";
import { useToast } from 'provider/ToastProvider'

/**
 * Props for the ReportDetailsCard component.
 * 
 * @interface ReportDetailsCardProps
 * @property {ReportDetails} details - The details of the report.
 * @property {boolean} ableToPurchase - Indicates if the report can be purchased.
 * @property {boolean} hasAccessToReport - Indicates if the user has access to the report.
 * @property {() => void} handleBuyReportClick - Function to handle the buy report button click.
 */
interface ReportDetailsCardProps {
    details: ReportDetails;
    ableToPurchase: boolean;
    hasAccessToReport: boolean;
    handleBuyReportClick: () => void;
}

/**
 * ReportDetailsCard component displays the details of a report and provides options to purchase or view the report.
 * 
 * @component
 * @param {ReportDetailsCardProps} props - The props for the component.
 * @param {Object} props.details - The details of the report.
 * @param {boolean} props.ableToPurchase - Indicates if the report is available for purchase.
 * @param {boolean} props.hasAccessToReport - Indicates if the user has access to the report.
 * @param {Function} props.handleBuyReportClick - Function to handle the buy report click event.
 * 
 * @returns {JSX.Element} The rendered ReportDetailsCard component.
 * 
 * @example
 * <ReportDetailsCard 
 *   details={reportDetails} 
 *   ableToPurchase={true} 
 *   hasAccessToReport={false} 
 *   handleBuyReportClick={handleBuyReport} 
 * />
 */
const ReportDetailsCard: React.FC<ReportDetailsCardProps> = ({ details, ableToPurchase, hasAccessToReport, handleBuyReportClick }) => {
    const { addToast } = useToast()
    const [selectedFormat, setSelectedFormat] = useState<'PDF' | 'PPT' | 'DOC' | 'DOCX'>(details.formats[0]?.type as 'PDF' | 'PPT' | 'DOC' | 'DOCX' || 'PDF')
    const [availableFormats, setAvailableFormats] = useState<Option[]>([])
    const { user } = useAuth()
    const navigate = useNavigate()
    
    const handleLoginClicked = () => {
        navigate('/login', {
            state: {
                redirect: `/report/${details.id}`
            }
        })
    }

    const handleBuyClicked = () => {
        handleBuyReportClick()
    }

    useEffect(() => {
        const options: Option[] = details.formats.map(format => {
            const option: Option = {
                id: format.type,
                name: format.type
            };
            return option;
        });
        setAvailableFormats(options);
    }, [details])

    const handleFormatClick = (option: string) => {
        setSelectedFormat(option as 'PDF' | 'PPT' | 'DOC' | 'DOCX')
        console.log(selectedFormat)
    }

    const handleShareClicked = () => {
        const link = `https://zeroneanalytiqs.com/report/${details.id}`

        navigator.clipboard.writeText(link)

        addToast({
            message: 'Report link copied to clipboard',
            type: 'info'
        })
    } 

    return (
        <div className={styles['report-details-card']}>
            <div className={styles['header']}>
                <p className={styles['key']}>Report Details</p>
                <button className={styles['share-button']} onClick={handleShareClicked}>
                    <ReusableIcon 
                        icon={"fluent:share-20-regular"} 
                        className={styles['share-icon'] || 'share-icon'}
                        color={"#707070"}
                    />
                </button>
                {/* <h2 className={styles['title']}>{details.title}</h2> */}
            </div>
            <div className={styles['body']}>
                <div className={styles['item']}>
                    <p className={styles['key']}>Pages</p>
                    <p className={styles['value']}>{details.meta.pages}</p>
                </div>
                <div className={styles['item']}>
                    <p className={styles['key']}>Language</p>
                    <p className={styles['value']}>{details.meta.language}</p>
                </div>
                <div className={styles['item']}>
                    <p className={styles['key']}>Published</p>
                    <p className={styles['value']}>{details.meta.published}</p>
                </div>
            </div>
            <div className={styles['footer']}>
                {!hasAccessToReport ? (
                    <div className={styles['item']}>
                        <p className={styles['key']}>{ableToPurchase ? 'Price' : 'Price USD'}</p>
                        <p className={`${styles['value']} ${styles['price']}`}>{ableToPurchase ? `${details.meta.creditPrice} ${details.meta.creditPrice === 1 ? 'Credit' : 'Credits'}`: `$${details.meta.price}`}</p>
                    </div>
                ) : (
                    <div className={styles['item']}>
                        <p className={styles['key']}>You have access to this report</p>
                    </div>
                )}
                {!hasAccessToReport && (
                    <SearchDropdown options={availableFormats} buttonName={""} placeholder={"Select format"} buttonColor={"#5c96b5"} onSelect={handleFormatClick} />
                )}
                <PrimaryButton
                    text={user ? hasAccessToReport ? `View Report` : `Buy Report` : 'Log in to Buy'}
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    icon={''}
                    doSomething={user ? handleBuyClicked : handleLoginClicked} 
                    width={'100%'}
                />
            </div>
        </div>
    )
}

export default ReportDetailsCard;