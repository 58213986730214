import React, { useEffect, useState } from 'react';
import styles from 'styles/components/AccountTypeForm.module.sass';
import { useLocation, useNavigate } from 'react-router-dom';
import SecondaryButton from 'components/common/SecondaryButton';
import PrimaryButton from 'components/common/PrimaryButton';
import CreateAccount from 'interface/CreateAccount';
import accountTypes from 'constants/data/AccountTypes';
import AccountType from 'interface/AccountType';
import ToggleSwitch from 'components/common/ToggleSwitch';
import LocationState from 'interface/LocationState';

/**
 * AccountTypeForm component allows users to choose an account type during the sign-up process.
 * 
 * @component
 * 
 * @example
 * // Renders the AccountTypeForm component
 * <AccountTypeForm />
 * 
 * @see PrimaryButton - Button component for the "Next" action
 * @see SecondaryButton - Button component for the "Login" action
 * 
 * @description
 * This form presents users with different account types to choose from:
 * - Free: Basic access to blogs and trending reports
 * - Basic and Sapphire: Mid-tier options with additional features
 * - Gold and Platinum: Premium options with full access to services
 * 
 * Users select an account type via radio buttons. The form includes a "Next" button to proceed
 * with the selected account type and a "Login" option for existing users.
 * The component uses a responsive layout to display options in rows on larger screens
 * and in a single column on smaller screens.
 */

const AccountTypeForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isPlanChange, ...accountParams } = (location.state || {}) as LocationState

  const [selectedAccount, setSelectedAccount] = useState<'free' | 'basic' | 'sapphire' | 'gold' | 'platinum'>('basic');
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'annual'>(accountParams.billingCycle? accountParams.billingCycle : 'monthly')
  const billingOptions = ['monthly', 'annual']

  // Redirect to /sign-up if accountParams are empty
  useEffect(() => {
    if (!location.state || typeof location.state !== 'object' || Object.keys(location.state as object).length === 0) {
      navigate('/sign-up')
      return
    }
    setSelectedAccount(accountParams.accountType ? accountParams.accountType : 'basic')
    //setBillingCycle(accountParams.billingCycle || 'monthly')
  }, [isPlanChange, navigate, location])

  const handleNextClicked = (event: React.FormEvent) => {
    event.preventDefault();

    const updatedAccountParams: CreateAccount = {
      ...accountParams,
      accountType: selectedAccount,
      billingCycle: billingCycle
    }

    navigate('/sign-up-payment', {
      state: { ...updatedAccountParams, isPlanChange: isPlanChange || false }
    })
  };

  const handleBillingclicked = (option: string) => {
    setBillingCycle(option as 'monthly' | 'annual')
  }

  const handleLoginClicked = () => {
    navigate('/login');
  };

  return (
    <form className={styles['account-form']}>
      <h2 className={styles['form-title']}>Choose Account Type</h2>
      <div className={styles['billing-cycle-container']}>
        <p className={styles['billing-note']}>
          All plans are <span className={styles['highlight']}>50% off now!</span> Workspace is coming soon - grab the discount before it launches at full price!
        </p>
        <ToggleSwitch
          selectedOption={billingCycle}
          options={billingOptions}
          onOptionChange={handleBillingclicked}
        />
      </div>
      
      <div className={styles['account-options']}>
        {accountTypes.map((account: AccountType, index: number) => (
          <div 
            key={index} 
            className={`${styles['account-option']} ${selectedAccount === account.name ? styles['selected'] : ''}`}
            onClick={() => setSelectedAccount(account.name)}
          >
            <label className={styles['radio-label']}>
              <input
                type="radio"
                name="accountType"
                value={account.name}
                checked={selectedAccount === account.name}
                onChange={() => setSelectedAccount(account.name)}
                className={styles['radio-input']}
              />
              <span 
                className={styles['radio-text']}
                style={{ color: account.color }}
              >
                {account.name}
              </span>
            </label>
            <p className={styles['account-description']}>{account.description}</p>
        </div>
        ))}
      </div>

      <div className={styles['action-buttons']}>
        <PrimaryButton
          text='Next'
          width='auto'
          primaryColor='#FF5522'
          textColor='#FFFFFF'
          hoverColor='#FFFFFF'
          hoverTextColor='#FF5522'
          doSomething={(event: React.FormEvent) => handleNextClicked(event)}
        />
        <SecondaryButton
          text="Have an account? Login"
          width='auto'
          primaryColor="transparent"
          textColor="#ff5522"
          hoverColor="#ff5522"
          hoverTextColor="#FFFFFF"
          doSomething={handleLoginClicked}
        />
      </div>
    </form>
  );
};

export default AccountTypeForm;
