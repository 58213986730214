import React, { useEffect, useState } from 'react'
import styles from 'styles/pages/ProfilePage.module.sass'
import { fetchProfile } from 'api/AccountApiService'
import BasicProfile from 'interface/BasicProfile'
import logInfo from 'services/logService/infoLogger'
import ZeroneLoader from 'components/common/ZeroneLoader'
import ProfileHeaderSection from 'layouts/section/ProfileHeaderSection'
import ProfileBodySection from 'layouts/section/ProfileBodySection'
import FooterMain from 'components/layout/footer/FooterMain'
import CancelSubscriptionConfirmationCard from 'components/cards/CancelSubscriptionConfirmationCard'

const TAG = 'ProfilePage'

/**
 * ProfilePage component - A page layout for displaying user profile details.
 *
 * This component fetches the user profile data and displays it using the
 * `ProfileHeaderSection` and `ProfileBodySection` components. It also includes
 * a footer section (`FooterMain`) for consistent layout.
 *
 * @component
 * @example
 * // Example usage:
 * <ProfilePage />
 *
 * @returns {JSX.Element} The rendered ProfilePage component.
 */
const ProfilePage: React.FC = () => {
    const [profile, setProfile] = useState<BasicProfile | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [fieldsUpdated, setFieldsUpdated] = useState<boolean>(false)
    const [tab, setTab] = useState<'basic' | 'reports' | 'account'>('basic')
    const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false)

    
    const loadProfile = async () => {
        setLoading(true)
        try {
            const fetchedProfile = await fetchProfile('profile')
            setProfile(fetchedProfile as BasicProfile | null)

            if (fetchedProfile) {
                logInfo(TAG, { message: 'Profile loaded' }, TAG)
            }
        } catch {
            setProfile(null)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadProfile()
    }, [])

    useEffect(() => {
        console.log('Fields updated:', fieldsUpdated)
        if (fieldsUpdated) {
            loadProfile()
            setFieldsUpdated(false)
        }
    }, [fieldsUpdated])

    const handleCancelSubscription = () => {
        console.log('Cancel subscription clicked')
        setShowCancelSubscriptionDialog(true)
    }

    return (
        <div className={styles['profile-page']}>
            {loading && (
                <div className={styles['loader']}>
                    <ZeroneLoader text='Loading profile...' />
                </div>
            )}
            <ProfileHeaderSection 
                profile={profile}
                tab={tab}
                setTab={setTab}
            />
            <ProfileBodySection 
                profile={profile} 
                tab={tab} 
                onCancelSubscription={handleCancelSubscription}
                setFieldsUpdated={setFieldsUpdated}
            />
            <FooterMain />
            {showCancelSubscriptionDialog && (
                <div className={styles['overlay']}>
                    <CancelSubscriptionConfirmationCard onClose={() => setShowCancelSubscriptionDialog(false)} />
                </div>
            )}
        </div>
    )
}

export default ProfilePage;
