import { checkAccessToReport, fetchReport, downloadReport, purchaseReportUsingCredits, getReportPurchaseIntent } from 'api/ReportApiService'
import UnlimitedReportAccessCard from 'components/cards/UnlimitedReportAccessCard'
import ReportSummaryBody from 'components/common/ReportSummaryBody'
import FooterMain from 'components/layout/footer/FooterMain'
import ReportHeaderSection from 'components/layout/report/ReportHeaderSection'
import ZeroneReport from 'interface/ZeroneReport'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from 'styles/pages/ReportPage.module.sass'
import { logError } from "services/logService/errorLogger"
import logInfo from 'services/logService/infoLogger'
import ZeroneLoader from 'components/common/ZeroneLoader'
import DownloadReportCard from 'components/cards/DownloadReportCard'
import { useToast } from 'provider/ToastProvider'
import { useAuth } from 'hooks/AuthContext'

const TAG = 'ReportPage'

/**
 * ReportPage component - A page layout for displaying report details.
 *
 * This component fetches a report based on the `id` extracted from the URL parameters
 * and displays the report details using the `ReportHeaderSection` component. It also includes
 * a footer section (`FooterMain`) for consistent layout.
 *
 * @component
 * @example
 * // Example usage:
 * <ReportPage />
 *
 * @returns {JSX.Element} The rendered ReportPage component.
 */
const ReportPage: React.FC = () => {
    const { addToast } = useToast()
    const [report, setReport] = useState<ZeroneReport | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [url, setUrl] = useState<string>('')
    const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false)
    const [loadingMessage, setLoadingMessage] = useState<string>('')
    const [hasAccessToReport, setHasAccessToReport] = useState<boolean>(false)
    const [ableToPurchase, setAbleToPurchase] = useState<boolean>(false)
    const [showBuyDialog, setShowBuyDialog] = useState<boolean>(false)
    const navigate = useNavigate()
    const { user } = useAuth()

    const { id } = useParams<{ id: UUID }>()

    const showToast = (type: 'error' | 'success' | 'warning' | 'info', toast: string) => {
        addToast({
            message: toast,
            type
        })
    }
    
    useEffect(() => {
        const loadReport = async () => {
            if (id) {
                try {
                    const fetchedReprot = await fetchReport({ tag: TAG, id })
                    setReport(fetchedReprot)
                    await checkAccess()
                } catch {
                    setReport(null)
                }
                await checkAccess()
            }
        }

        const checkAccess = async () => {
            if (id) {
                try {
                    const access = await checkAccessToReport({ tag: TAG, id })
                    setHasAccessToReport(access.hasAccess)
                    setAbleToPurchase(access.ableToPurchase)
                } catch {
                    setHasAccessToReport(false)
                    setAbleToPurchase(false)
                }
            }
        }

        loadReport()
    }, [])

    const accessMyReport = async () => {
        if (id) {
            setLoadingMessage('Accessing report...')
            setLoading(true)

            try {
                const url = await downloadReport({ tag: TAG, id })
                if (url) {
                    setUrl(url)
                    setShowDownloadDialog(true)
                    logInfo('Report downloaded successfully.', { reportId: id, url }, TAG)
                    showToast('success', 'Report downloaded successfully.')
                } else {
                    logError('Error downloading report.', { error: 'Unknown error'}, TAG)
                    setLoadingMessage('')
                    setLoading(false)
                    showToast('error', 'Error downloading report. Please try again later.')
                }
            } catch (error) {
                logError('Error downloading report.', { error: error instanceof Error ? error.message : 'Unknown error'}, TAG)
                showToast('error', 'Error downloading report. Please try again later.')
            }
            setLoading(false)
            setLoadingMessage('')
        }
    }

    const purchaseUsingCredits = async () => {
        if (id) {
            setLoadingMessage('Purchasing report...')
            setLoading(true)

            try {
                const purchaseResult = await purchaseReportUsingCredits({ tag: TAG, id })

                if (!purchaseResult || !purchaseResult.success) {
                    logError('Error purchasing report.', { error: purchaseResult?.message || 'Unknown error'}, TAG)
                    setLoadingMessage('')
                    setLoading(false)
                    showToast('error', 'Error purchasing report. Please try again later.')
                    return
                }

                if (purchaseResult.url) {
                    setUrl(purchaseResult.url)
                    setShowDownloadDialog(true)
                    logInfo('Report purchased successfully.', { reportId: id, url: purchaseResult.url }, TAG)
                    showToast('success', `Report purchased successfully. ${purchaseResult.creditsUsed} ${purchaseResult.creditsUsed > 1 ? 'credits' : 'credit'} used.`)
                } else {
                    logError('Error purchasing report.', { error: 'Unknown error'}, TAG)
                    showToast('error', 'Error purchasing report. Please try again later.')
                }
            } catch (error) {
                logError('Error purchasing report.', { error: error instanceof Error ? error.message : 'Unknown error'}, TAG)
                showToast('error', 'Error purchasing report. Please try again later.')
            }
            setLoading(false)
            setLoadingMessage('')
        }
    }

    const purchaseUsingPayment = async (): Promise<void> => {
        if (id) {
            setLoadingMessage('Getting secure payment link...')
            setShowBuyDialog(false)
            setLoading(true)

            try {
                const purchaseResult = await getReportPurchaseIntent({ tag: TAG, id })

                if (!purchaseResult || !purchaseResult.success) {
                    logError('Error purchasing report.', { error: purchaseResult?.message || 'Unknown error'}, TAG)
                    setLoadingMessage('')
                    setLoading(false)
                    showToast('error', 'Error purchasing report. Please try again later.')
                    return
                }

                if (purchaseResult.url) {
                    setUrl(purchaseResult.url)
                    setShowDownloadDialog(true)
                    logInfo('User already had access to the report.', { reportId: id }, TAG)
                    showToast('success', `You already have access to this report. no purchase needed.`)
                } else if (purchaseResult.paymentIntent && purchaseResult.paymentIntent.clientSecret && purchaseResult.paymentIntent.amount) {
                    logInfo('User is able to purchase the report.', { reportId: id, purchaseResult: purchaseResult.paymentIntent }, TAG)

                    if (purchaseResult.paymentIntent.amount <= 0 || purchaseResult.paymentIntent.amount > 1000000 || purchaseResult.paymentIntent.clientSecret.trim().length === 0) {
                        logError('Error purchasing report.', { error: 'Invalid payment amount'}, TAG)
                        showToast('error', 'Error purchasing report. Please try again later.')
                        setLoading(false)
                        setLoadingMessage('')
                        return
                    }

                    logInfo('User is able to purchase the report.', { reportId: id, purchaseResult: purchaseResult.paymentIntent }, TAG)
                    showToast('info', 'Redirecting to secure payment page...')
                    navigate(`/report/payment/${id}`, { state: { paymentIntent: purchaseResult.paymentIntent, title: report?.title } })
                } else {
                    logError('Error purchasing report.', { error: 'Unknown error'}, TAG)
                    showToast('error', 'Error purchasing report. Please try again later.')
                }
            } catch (error) {
                logError('Error purchasing report.', { error: error instanceof Error ? error.message : 'Unknown error'}, TAG)
                showToast('error', 'Error purchasing report. Please try again later.')
            }
            setLoading(false)
            setLoadingMessage('')
        }
    }

    const handlePurchaseByPaymentWrapper = () => {
        void purchaseUsingPayment()
    }

    const viewAllPlans = () => {
        if (user) {
            navigate('/change-plan')
        } else {
            navigate('/pricing')
        }
    }

    const handleBuyReportClick = async () => {
        if (hasAccessToReport) {
            logError('User already has access to the report.', { reportId: id }, TAG)
            setShowBuyDialog(false)
            await accessMyReport()
        } else if (ableToPurchase) {
            logInfo('User is able to purchase the report.', { reportId: id }, TAG)
            setShowBuyDialog(false)
            await purchaseUsingCredits()
        } else {
            logInfo('User does not have access to the report and is not able to purchase the report.', { reportId: id }, TAG)
            setShowBuyDialog(true)
        }
    }

    const handleBuyClickedWrapper = () => {
        handleBuyReportClick()
    }

    return (
        <div className={styles['report-page']}>
            <ReportHeaderSection report={report} ableToPurchase={ableToPurchase} hasAccessToReport={hasAccessToReport} handleBuyReportClick={handleBuyClickedWrapper}  />
            {loading && (
                <div className={styles['loader']}>
                    <ZeroneLoader text={loadingMessage} />
                </div>
            )}
            {showDownloadDialog && url.length > 0 && (
                <div className={styles['overlay']}>
                    <DownloadReportCard
                        url={url}
                        title={report?.title || ''}
                        onClose={() => setShowDownloadDialog(false)}
                    />
                </div>   
            )}
            <ReportSummaryBody 
                tableOfContents={report?.tableOfContents || []}
                showcase={report?.showcase ? report.showcase ?? null : null }
                hasAccessToReport={hasAccessToReport}
            />
            <FooterMain />
            {showBuyDialog && (
                <div className={styles['overlay']}>
                    <UnlimitedReportAccessCard 
                        onBuyOnlyThisReport={() => handlePurchaseByPaymentWrapper()} 
                        onViewAllPlans={function (): void {
                            viewAllPlans()
                        } } 
                        onClose={function (): void {
                            setShowBuyDialog(false)
                        } } 
                    />
                </div>
            )}
        </div>
    )
}

export default ReportPage;