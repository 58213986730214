import React from 'react'
import styles from 'styles/components/TopicCard.module.sass'
import logo from 'assets/images/svg/logo-light.svg'
import { TopicCardProps } from 'interface/TopicCardProps'
import { useNavigate } from 'react-router-dom'

/**
 * TopicCard component displays a clickable card for a specific topic,
 * featuring a highlight, title, year, and a watermark logo.
 *
 * @component
 * @param {TopicCardProps} props - The properties for the component.
 * @param {UUID} props.id - Unique identifier for the topic, used for navigation.
 * @param {string} props.highlight - Highlight text to emphasize the topic, e.g., "Trending Topics."
 * @param {string} props.title - Title of the topic displayed prominently on the card.
 * @param {string} props.year - The year associated with the topic, typically indicating publication or relevance.
 *
 * @example
 * <TopicCard
 *   id="fabd37f0-7bd9-413a-b782-cbc73fb01b00"
 *   highlight="Trending Topics"
 *   title="The Power of AI"
 *   year="2024"
 * />
 *
 * @returns {JSX.Element} A clickable topic card component with a header, title, year, and watermark logo.
 */
const TopicCard: React.FC<TopicCardProps> = ({ id, highlight, title, year, cover }) => {
  const navigate = useNavigate()
    /**
     * Handles the click event on the card, logging the topic ID,
     * navigating to the topic's page, and scrolling to the top of the page.
     */
    function handleCardClick(): void {
        navigate(`/report/${id}`)
    }

    return (
        <div className={styles['topic-card']} onClick={handleCardClick}>
            <div className={styles['header']}>
                <p className={styles['highlight']}>{highlight}</p>
                <p className={styles['title']}>{title}</p>
            </div>
            <div className={styles['body']} style={{ backgroundImage: cover ? `url(${cover})` : 'none' }}>
                <p className={styles['year']}>{year}</p>
            </div>
            <div className={styles['watermark']}>
                <img src={logo} alt={`Zerone Analytiqs © ${new Date().getFullYear()}`} className={styles['logo']} />
            </div>
        </div>
    )
}

export default TopicCard;