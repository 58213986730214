import EndpointProps from "interface/EndpointProps";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import { fetchApiData } from "../ApiHelper";
import { post } from "../ApiClient";

/**
 * This function handles the addition of a user to the waitlist.
 * It sends a POST request to the "/waitlist" endpoint with the user's email.
 * It returns a success message if the request is successful, or an error message if it fails.
 * 
 * @async
 * @function addToWaitlist
 * @param {Object} param0 - The request parameters containing a unique tag and waitlist payload.
 * @param {string} param0.tag - A unique identifier for the API request.
 * @param {Object} param0.data - The data payload containing the user's email.
 * @param {string} param0.data.email - The email address of the user to be added to the waitlist.
 * @returns {Promise<{ success: boolean; message: string }>} - A promise that resolves to an object containing the success status and a message.
 * @throws {Error} - Throws an error if the API request fails or if the response is not as expected.
 * 
 * @example
 * // Example usage:
 * const response = await addToWaitlist({
 *    tag: "unique-tag-123",
 *   data: { email: "zerone@zerone.com" }
 * });
 * 
 * if (response.success) {
 *  console.log(response.message); // "Thank you for joining the waitlist! We'll keep you updated."
 * } else {
 *  console.error(response.message); // "An error occurred while adding to the waitlist. Please try again later."
 * }
 */
export async function addToWaitlist({ tag, data }: EndpointProps & { data: { email: string }}): Promise<{ success: boolean; message: string}> {
    if (!data) {
        return {
            success: false,
            message: "Invalid waitlist request: Missing data payload.",
        };
    }

    const result = await fetchApiData<DefaultApiResponse>(
        tag,
        () =>
            post<DefaultApiResponse>({
                tag,
                url: "/waitlist",
                body: data,
            }),
        (responseData) => responseData
    );

    if (result.response.status === "error") {
        return {
            success: false,
            message: result.response.message || "An error occurred while adding to the waitlist. Please try again later.",
        };
    }

    return {
        success: true,
        message: result.response.message || "Thank you for joining the waitlist! We'll keep you updated.",
    };
}