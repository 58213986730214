import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import styles from 'styles/components/StripePaymentForm.module.sass'
import { useToast } from 'provider/ToastProvider';
import StripeError from 'interface/StripeError';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
if (!publishableKey) {
    throw new Error('Stripe publishable key is not set.')
}

/**
 * Props for the StripePaymentForm component.
 * 
 * @interface StripePaymentFormProps
 * 
 * @property {string} clientSecret - The client secret obtained from the Stripe API, used to confirm the payment.
 * @property {string | number} [totalAmount] - The total payment amount in the smallest currency unit (e.g., cents for USD). Optional.
 * @property {string} [message] - A custom message to display to the user, providing context or reassurance.
 * Example: "Join Our Community of Satisfied Users by Completing Your Secure Payment."
 * @property {(paymentId: string) => void} onPaymentSuccess - Callback triggered when the payment is successfully completed, receiving the payment ID as a parameter.
 * @property {(errorMessage: string) => void} onPaymentFailure - Callback triggered when the payment fails, receiving an error message as a parameter.
 * 
 * @example
 * const stripePaymentProps: StripePaymentFormProps = {
 *     clientSecret: "sk_test_51HxxxxxxSecretKey",
 *     totalAmount: 4999, // Amount in cents (e.g., $49.99)
 *     message: "Join Our Community of Satisfied Users by Completing Your Secure Payment.",
 *     onPaymentSuccess: (paymentId) => {
 *         console.log("Payment successful with ID:", paymentId);
 *     },
 *     onPaymentFailure: (errorMessage) => {
 *         console.error("Payment failed with error:", errorMessage);
 *     },
 * };
 */
interface StripePaymentFormProps {
    clientSecret: string;
    totalAmount?: string | number;
    message?: string;
    showInfo?: boolean;
    regularPayment?: boolean;
    onPaymentSuccess: (paymentId: string) => void;
    onPaymentFailure: (errorMessage: string) => void;
    onCancelPayment: () => void;
}

/**
 * StripePaymentForm component for processing secure payments via Stripe.
 * 
 * @component
 * 
 * @param {StripePaymentFormProps} props - Props for the component.
 * 
 * @param {string} props.clientSecret - The client secret from the Stripe API for confirming the payment.
 * @param {string | number} [props.totalAmount] - Total payment amount in the smallest currency unit (e.g., cents for USD).
 * @param {string} [props.message='Proceed with Your Secure Payment'] - A custom message to display on the payment form.
 * Example: "Join Our Community of Satisfied Users by Completing Your Secure Payment."
 * @param {(paymentId: string) => void} props.onPaymentSuccess - Callback executed on successful payment, receiving the payment ID.
 * @param {(errorMessage: string) => void} props.onPaymentFailure - Callback executed on payment failure, receiving an error message.
 * 
 * @example
 * <StripePaymentForm
 *   clientSecret="sk_test_51HxxxxxxSecretKey"
 *   totalAmount={4999}
 *   message="Join Our Community of Satisfied Users by Completing Your Secure Payment."
 *   onPaymentSuccess={(paymentId) => console.log('Payment successful:', paymentId)}
 *   onPaymentFailure={(errorMessage) => console.error('Payment failed:', errorMessage)}
 * />
 */
const StripePaymentForm: React.FC<StripePaymentFormProps> = ({
    clientSecret,
    totalAmount,
    message = 'Proceed with Your Secure Payment',
    showInfo = true,
    regularPayment = false,
    onPaymentSuccess,
    onPaymentFailure,
    onCancelPayment
}) => {
    const { addToast } = useToast()
    const stripe = useStripe()
    const elements = useElements()
    const [failedAttempts, setFailedAttempts] = useState<number>(0)
    const [showFreePlanOption, setShowFreePlanOption] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (!stripe || !elements) {
            onPaymentFailure('Stripe has not loaded yet.')
            return;
        }

        setIsSubmitting(true)

        try {
            // Retrieve PaymentIntent status before proceeding
            const { paymentIntent: existingPaymentIntent, error: retrieveError } = await stripe.retrievePaymentIntent(clientSecret);

            if (retrieveError) {
                onPaymentFailure(retrieveError.message || 'Error retrieving payment status.');
                setIsSubmitting(false);
                return;
            }

            // If the payment has already succeeded, avoid reconfirmation
            if (existingPaymentIntent?.status === "succeeded") {
                onPaymentSuccess(existingPaymentIntent.id);
                setIsSubmitting(false);
                return;
            }

            const cardElement = elements.getElement(CardElement)
            if (!cardElement) {
                onPaymentFailure('CardElement not found.')
                setIsSubmitting(false)
                return;
            }

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: { card: cardElement },
                setup_future_usage: 'off_session',
            })

            if (error) {
                const stripeError = error as StripeError || Error

                const errorMessage = 
                    stripeError.decline_code ||
                    stripeError.message ||
                    stripeError.code ||
                    'Unexpected error while processing payment.'

                onPaymentFailure(errorMessage)

                // Increment failed attempts.
                setFailedAttempts((prev) => {
                    const newCount = prev + 1

                    // After 5 failed attempts, cancel payment
                    if (newCount >= 5 && onCancelPayment) {
                        onCancelPayment()
                    }

                    // Show free plan option after 3 failed attempts
                    if (newCount === 3) {
                        addToast({
                            type: 'warning',
                            message: `You've had ${failedAttempts + 1} failed attempts.`
                        })
                        setShowFreePlanOption(true)
                    }

                    return newCount
                })
                setIsSubmitting(false)
            } else if (paymentIntent) {
                onPaymentSuccess(paymentIntent.id)
                setIsSubmitting(false)
            } else {
                onPaymentFailure('Unexpected error while processing payment.')
                setIsSubmitting(false)
            }
        } catch (error) {
            console.error('Error processing payment:', error)
            onPaymentFailure('An unexpected error occurred.')
            setIsSubmitting(false)
        }
    }

    const handleCancelPayment = () => {
        setShowFreePlanOption(false)
        onCancelPayment?.()
    }

    const handleSubmitWrapper = (event: React.FormEvent) => {
        void handleSubmit(event)
    }

    const cardElementOptions = {
        style: {
            base: {
                width: '100%',
                height: 'auto',
                minHeight: 'auto',
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Poppins, sans-serif',
                padding: '10px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
    

    return (
            /* eslint-disable @typescript-eslint/no-misused-promises */
        <form onSubmit={handleSubmitWrapper} className={styles['payment-form']}>
            {(message && totalAmount) && (
                <>
                <h2 className={styles['message']}>{message && showInfo ? message : 'Enter your card details to proceed.'}</h2>
                {showInfo &&
                    <p className={styles['total-amount']}>
                        <span>Total:</span> {typeof totalAmount === 'number' ? `$${(totalAmount).toFixed(2)}` : totalAmount}
                    </p>
        }
                </>
            )}
            <div className={styles['card-element-container']}>
                <CardElement options={cardElementOptions} />
            </div>
            <button
                type="submit"
                disabled={!stripe || isSubmitting}
                className={styles['submit-button']}
            >
                {isSubmitting ? "Processing..." : "All Done!"}
            </button>

            {/** Free plan option after 3 failed attempts */}
            {showFreePlanOption && (
                <div className={styles['free-plan-option']}>
                    <p className={styles['payment-message']}>{!regularPayment ? `Having trouble. You can try another card or switch to the free plan.` : `Try another card later?` }</p>
                    <button
                        type='button'
                        className={styles['free-plan-button']}
                        onClick={handleCancelPayment}    
                    >
                        {!regularPayment? 'Switch to Free Plan' : 'Cancel Payment'}
                    </button>
                </div>
            )}
        </form>
        /* eslint-enable @typescript-eslint/no-misused-promises */
    )
}

export default StripePaymentForm;