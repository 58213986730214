import React, { useState } from 'react';
import InputField from 'components/common/InputField';
import PrimaryButton from 'components/common/PrimaryButton';
import styles from 'styles/components/ContactForm.module.sass';
import ZeroneLoader from 'components/common/ZeroneLoader';
import { validateAndSanitizeContactInquiry } from 'utils/ValidateAndSanitizeContactInquiry';
import { useToast } from 'provider/ToastProvider';
import { processSubmitContactInquiry } from 'api/ContactApiService';
import { logError } from 'services/logService/errorLogger';
import ReusableIcon from 'components/common/ReusableIcon';

interface ContactFormProps {
    showCloseButton?: boolean;
    onClose?: () => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ showCloseButton = false, onClose }) => {
    const { addToast } = useToast()
    const [loading, setLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    console.log('Show close button', showCloseButton);

    const handleSubmit = (event: React.FormEvent) => {
        setLoading(true);
        event.preventDefault();
        const { isValid, errors, sanitizedData } = validateAndSanitizeContactInquiry({
            firstName,
            lastName,
            email,
            phoneNumber: phone,
            message
        });

        if (!isValid) {
            addToast({
                type: 'error',
                message: errors.join(' ')
            })
            setLoading(false);
            return;
        }

        processSubmitContactInquiry(sanitizedData).then((submitInquiry: boolean) => {
            if (submitInquiry) {
                addToast({
                    type: 'success',
                    message: 'Thank you for your inquiry. Our team will review your message and get back to you shortly.'
                })
            } else {
                addToast({
                    type: 'error',
                    message: 'Failed to submit your inquiry. Please try again later.'
                })
            }
        }).catch((error) => {
            logError('Error submitting contact inquiry', { error: error instanceof Error ? error.message : '' }, 'ContactForm');
            addToast({
                type: 'error',
                message: 'An error occurred while submitting your inquiry. Please try again later.'
            })
        })
        .finally(() => {
            setLoading(false);
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setMessage('');
        });
    };

    return (
        <form className={styles['contact-form']}>
            {showCloseButton && (
                <button className={styles['close-button']} onClick={onClose}>
                    <ReusableIcon
                        icon={'qlementine-icons:close-16'}
                        className={styles['close-icon'] || 'close-icon'}
                        color={'#707070'}
                    />
                </button>
            )}
            <h2 className={styles['form-title']}>Reach out to us</h2>
            <div className={styles['all-input']}>
                <div className={styles['input-row']}>
                    <InputField 
                        label={'First Name'} 
                        type={'text'} 
                        value={firstName} 
                        onChange={setFirstName}
                        required={true} 
                    />
                    <InputField 
                        label={'Last Name'} 
                        type={'text'} 
                        value={lastName} 
                        onChange={setLastName} 
                        required={true} 
                    />
                </div>

                <div className={styles['input-row']}>
                    <InputField 
                        label={'Email'} 
                        type={'email'} 
                        value={email} 
                        onChange={setEmail} 
                        required={true} 
                    />
                    <InputField 
                        label={'Phone Number'} 
                        type={'tel'} 
                        value={phone} 
                        onChange={setPhone} 
                        required={false} 
                    />
                </div>

                <div className={styles['input-row']}>
                    <InputField
                        label={'How can we help you?'}
                        type={'textarea'}
                        value={message}
                        onChange={setMessage}
                        required={true}

                    />
                </div>
            </div>

            <div className={styles['action-buttons']}>
                {loading ? (
                    <div className={styles['loader-container']}>
                        <ZeroneLoader
                            text='Submitting your inquiry...'
                        />
                    </div>
                    ) : (
                        <PrimaryButton
                            text='Submit'
                            primaryColor='#FF5522'
                            textColor='#FFFFFF'
                            hoverColor='#FFFFFF'
                            hoverTextColor='#FF5522'
                            doSomething={(event: React.FormEvent) => handleSubmit(event)}
                        />
                )}
            </div>
        </form>
    );
};

export default ContactForm;