import React, { useEffect, useState } from "react";
import botImage from 'assets/images/svg/zerone-bot-3-d-2.svg'
import styles from 'styles/components/ProfilePictureWrapper.module.sass'
import ReusableIcon from "./ReusableIcon";
import endpoints from "constants/api/Endpoints";
import { logError } from "services/logService/errorLogger";
import { useToast } from "provider/ToastProvider";
import { v4 as uuidv4 } from "uuid";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "constants/data/global/firebaseConfig";
import ZeroneLoader from "./ZeroneLoader";

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

const tag = 'ProfilePictureWrapper'

// Props for the ProfilePictureWrapper component.
interface ProfilePictureWrapperProps {
    profilePicture: string;
}

/**
 * ProfilePictureWrapper component displays the profile picture of the user.
 *
 * @component
 * @param {ProfilePictureWrapperProps} props - The properties object.
 * @param {string} props.profilePicture - The URL of the profile picture.
 *
 * @example
 * <ProfilePictureWrapper
 *   profilePicture='https://example.com/profile.jpg'
 * />
 */
const ProfilePictureWrapper: React.FC<ProfilePictureWrapperProps> = ({ profilePicture }) => {
    const [avatar, setAvatar] = useState<string>(profilePicture.length > 0 ? profilePicture : botImage);
    const [uploadToken, setUploadToken] = useState<string>('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [imageID, setImageID] = useState<string>('');
    const { addToast } = useToast();

    /**
     * Retrieves an upload token from the server.
     * 
     * @returns {Promise<boolean>} A promise that resolves to a boolean value.
     * 
     * @throws {Error} Throws an error if the fetch operation fails.
     */
    const getUploadToken = async (): Promise<boolean> => {
        try {
            const tokenResult = await endpoints.fetchUploadToken({ tag });
            if (tokenResult && tokenResult.success && tokenResult.data) {
                setUploadToken(tokenResult.data.token);
                return true;
            } else {
                logError('Failed to fetch upload token', { error: 'Token' }, tag);
                return false;
            }
        } catch (error) {
            logError('Error fetching upload token', { error }, tag);
            return false;
        }
    }

    useEffect(() => {
        getUploadToken();
    }, []);

    useEffect(() => {
        if (profilePicture && profilePicture.length > 0) {
            setAvatar(profilePicture);
        } else {
            setAvatar(botImage);
        }
    }, [profilePicture]);

    /**
     * Selects a photo from the user's device.
     * 
     * @returns {Promise<void>} A promise that resolves to void.
     */
    const selectPhoto = (): Promise<void> => {
        return new Promise((resolve) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.click();

            input.addEventListener('change', (event) => {
                const target = event.target as HTMLInputElement;
                const file = target.files && target.files[0];
                if (file) {
                    if (!file.type.startsWith('image/')) {
                        addToast({
                            type: 'error',
                            message: 'Selected file is not an image. Please select a valid image file.'
                        });
                        resolve();
                        return;
                    }

                    // Check if the file size is less than 5MB
                    if (file.size > 5 * 1024 * 1024) {
                        addToast({
                            type: 'error',
                            message: 'Selected file is too large. Please select a file smaller than 5MB.'
                        });
                        resolve();
                        return;
                    }

                    setImageFile(file);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const result = e.target?.result;
                        if (result) {
                            setAvatar(result as string);
                        }
                        resolve();
                    };
                    reader.readAsDataURL(file);
                } else {
                    resolve();
                }
            });
        });
    }

    /**
     * Uploads the selected image to Firebase storage.
     * 
     * @returns {Promise<boolean>} A promise that resolves to a boolean value.
     */
    const uploadImage = async (): Promise<boolean> => {
        if (!app || !uploadToken || !imageFile) {
            logError('Firebase app not initialized or missing upload token or image file.', {}, tag);
            return false;
        }

        try {
            const imageFileCopy = new File([imageFile], `${imageID}.${imageFile.name.split('.').pop()}`, { type: imageFile.type });
            const imageFileName = imageFileCopy.name;

            const auth = getAuth(app);
            await signInWithCustomToken(auth, uploadToken);

            const storage = getStorage();
            const storageRef = ref(storage, `zerone-profiles-uploads/${imageFileName}`);

            const uploadTask = await uploadBytes(storageRef, imageFile as Blob);

            const downloadURL = await getDownloadURL(uploadTask.ref);
            if (!downloadURL) return false;

            addToast({
                type: 'success',
                message: 'Photo uploaded successfully.'
            })
            setAvatar(downloadURL);
            return true;
        } catch (error) {
            logError('Error uploading image', { error }, tag);
            return false;
        }
    }

    /**
     * Updates the profile photo on the server.
     * 
     * @returns {Promise<boolean>} A promise that resolves to a boolean value.
     */
    const updateServerSide = async (): Promise<boolean> => {
        try {
            const result = await endpoints.updateUserProfile({ tag, data: { profilePhoto: imageID }});
            if (result) {
                return true;
            } else {
                logError('Failed to update profile photo', { error: 'Update' }, tag);
                return false;
            }
        } catch (error) {
            logError('Error updating profile photo', { error }, tag);
            return false;
        }
    }

    /**
     * Handles the change photo event.
     * 
     * @returns {Promise<void>} A promise that resolves to void.
     */
    const handleChangePhoto = async (): Promise<void> => {
        try {
            if (!uploadToken || uploadToken.length < 1) {
                logError('Upload token not available', { error: 'Token' }, tag);
                addToast({
                    type: 'error',
                    message: 'Failed to upload photo. Please try again later.'
                })
                return;
            }
            await selectPhoto()
            
            if (!imageFile && uploadToken.length < 1) {
                addToast({
                    type: 'error',
                    message: 'Failed to upload photo. Please try again later.'
                })
                return;
            }

            setLoading(true)

            const id = uuidv4();
            setImageID(id);
            const firebaseUploadResult = await uploadImage()

            if (firebaseUploadResult) {
                const serverUpdateResult = await updateServerSide()
                if (serverUpdateResult) {
                    addToast({
                        type: 'success',
                        message: 'Photo uploaded successfully.'
                    })

                    /**
                     * Trigger auth change event to update user profile.
                     * @event authChange
                     * 
                     * This is a workaround to update the user profile after changing the profile photo.
                     * The authChange event is triggered to update the user profile in the application.
                    */
                    window.dispatchEvent(new Event('authChange'));  
                    return
                }
            }
            
            addToast({
                type: 'error',
                message: 'Failed to upload photo. Please try again later.'
            })
        } catch (error) {
            logError('Error uploading photo', { error }, tag);
            addToast({
                type: 'error',
                message: 'Failed to upload photo. Please try again later.'
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={styles['profile-picture-wrapper']}>
            <img src={avatar.length > 0 ? avatar : botImage} alt='Profile Picture' className={styles['profile-picture']} />
            {loading && 
                <div className={styles['loader-overlay']}>
                    <ZeroneLoader text=""/>
                </div>
            }
            <div className={styles['camera-overlay']} onClick={() => { handleChangePhoto(); }}>
                <ReusableIcon icon='mdi:camera' className={styles['camera-icon'] || 'camera-icon'} color='#fff' />
                <p className={styles['camera-text']}>Change photo</p>
            </div>
        </div>
    )
}

export default ProfilePictureWrapper;