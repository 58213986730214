/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import styles from 'styles/components/WorkspaceSection.module.sass';
import PrimaryButton from 'components/common/PrimaryButton';
import comingSoonImage from 'assets/images/png/coming-soon-btn.png';
import { useToast } from 'provider/ToastProvider';
import endpoints from 'constants/api/Endpoints';
import { useNavigate } from 'react-router-dom';
import { logError } from 'services/logService/errorLogger';
import ZeroneLoader from 'components/common/ZeroneLoader';

const WorkspaceSection: React.FC = () => {
  const { addToast } = useToast()
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate()

  const handleAddToWaitlist = async () => {
    setLoading(true);
    try {
      if (!email) {
        addToast({
          type: 'error',
          message: 'Please enter a valid email address.',
        });
        return;
      }
      const response = await endpoints.addToWaitlist({ tag: 'WorkspaceWaitlist', data: { email }});

      if (response.success) {
        addToast({ type: 'success', message: 'Exciting things are coming your way! But why wait? Download our exclusive reports now and stay ahead of the game!' });
        navigate('/report/categories')
      } else {
        const errorMessage = 'An error occurred. Please try again later.';
        addToast({ type: 'error', message: errorMessage });
      }
    } catch (error) {
      logError('Error adding to waitlist', { error: error instanceof Error ? error.message : 'Unknown error' }, 'WorkspaceSection');
      addToast({ type: 'error', message: 'An error occurred. Please try again later.' });
    } finally {
      setLoading(false);
      setEmail(''); // Clear email input after submission
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      addToast({
        type: 'error',
        message: 'Please enter a valid email address.',
      });
      return;
    }
    // Proceed with adding to waitlist
    handleAddToWaitlist();
  };

  return (
    <section className={styles.comingSoonSection}>
      <div className={styles.container}>
        <div className={styles.content}>
        {loading && (
          <div className={styles['loader-container']}>
            <ZeroneLoader />
          </div>
        )}
          <h1 className={styles.headline}>
            <span className={styles.workspace}>WORKSPACE</span>
            <span className={styles.comingSoon}>Coming Soon</span>
          </h1>
          <p className={styles.description}>
            Organize, analyze and visualize your data with our
            <br />
            AI-Powered comingSoon.
            <br />
            Be the first to know when it's ready!
          </p>
          <form onSubmit={handleSubmit} className={styles.form}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@example.com"
              className={styles.emailInput}
              required
            />
        
            <PrimaryButton
              text="Join the waitlist!"
              primaryColor="#FF5522"
              textColor="#FFFFFF"
              hoverColor="#FFFFFF"
              hoverTextColor="#FF5522"
              doSomething={handleSubmit}
              data-coming-btn
            />
          </form>
        </div>
        <div className={styles.imageContainer}>
          <img src={comingSoonImage} alt="Coming Soon" className={styles.comingSoonImage} />
        </div>
      </div>
    </section>
  );
};

export default WorkspaceSection;
