import React, { useEffect, useState } from "react";
import styles from 'styles/components/InterestedIndustries.module.sass';
import SecondaryButton from "./SecondaryButton";
import ReusableIcon from "./ReusableIcon";
import Industry from "interface/Industry";
import endpoints from "constants/api/Endpoints";
import { useToast } from "provider/ToastProvider";
import { logError } from "services/logService/errorLogger";

const tag = 'InterestedIndustries';

interface InterestedIndustriesProps {
    industries: Industry[];
    setShowAddIndustries: (show: boolean) => void;
}

/**
 * InterestedIndustries component - Displays the industries that a user is interested in.
 *
 * This component includes a list of industries that the user is interested in.
 *
 * @component
 * @param {InterestedIndustriesProps} props - The properties for the InterestedIndustries component.
 * @param {Industry[]} props.industries - The list of industries that the user is interested in.
 *
 * @example
 * // Example usage:
 * <InterestedIndustries industries={['Technology', 'Finance', 'Healthcare']} />
 *
 * @returns {JSX.Element} The rendered InterestedIndustries component.
 */
const InterestedIndustries: React.FC<InterestedIndustriesProps> = ({ industries, setShowAddIndustries }) => {
    const [userIndustries, setUserIndustries] = useState<Industry[]>(industries);
    const { addToast } = useToast();

    useEffect(() => {
        setUserIndustries(industries);
    }, [industries]);

    const handleAddIndustriesClick = () => {
        setShowAddIndustries(true);
    }

    const removeIndustry = async (industryId: string) => {
        try {
            const response = await endpoints.removeInterestedIndustry({ tag, id: industryId });
            if (response.success) {
                setUserIndustries(prev => prev.filter(industry => industry.id !== industryId));
                addToast({
                    type: 'success',
                    message: 'Industry removed successfully!'
                });
            } else {
                addToast({
                    type: 'error',
                    message: 'Failed to remove industry.'
                });
            }
        } catch (error) {
            logError('Failed to remove industry', { error }, tag);
            addToast({
                type: 'error',
                message: 'An error occurred while removing the industry.'
            });
        }
    }

    return (
        <div className={styles['interested-industries']}>
            <div className={styles['header']}>
                <h3 className={styles['title']}>Interested Industries</h3>
                <SecondaryButton
                    width="auto"
                    text="Add"
                    primaryColor="#ffffff"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#ffffff"
                    icon={'gala:add'}
                    doSomething={handleAddIndustriesClick}
                />
            </div>
            <ul className={styles['list']}>
                {userIndustries.map((industry, index) => (
                    <li key={index} className={styles['item']}>
                        {industry.industry}
                        <div className={styles['close-button']} onClick={() => { removeIndustry(industry.id); }}>
                            <ReusableIcon
                                icon={'si:close-circle-line'}
                                className={styles['close-icon'] || 'close-icon'}
                                color=""
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default InterestedIndustries;