import SecondaryButton from 'components/common/SecondaryButton';
import { updateUserProfile } from 'constants/api/endpoints/ProfileEndpoints';
import BasicProfile from 'interface/BasicProfile';
import UpdateUserProfileOptions from 'interface/UpdateUserProfileOptions';
import React, { useEffect, useState } from 'react';
import styles from 'styles/components/BasicInfo.module.sass';
import { useToast } from 'provider/ToastProvider';
import { logError } from 'services/logService/errorLogger';
import InterestedIndustries from 'components/common/InterestedIndustries';

const tag = 'BasicInfo';

interface BasicInfoProps {
    profile: BasicProfile;
    setLoading: (loading: boolean) => void;
    setFieldsUpdated: (updated: boolean) => void;
    setShowAddIndustries: (show: boolean) => void;
}
/**
 * BasicInfo component - Displays the basic information of a profile.
 *
 * This component includes the profile name, email, and phone number.
 *
 * @component
 * @param {BasicInfoProps} props - The properties for the BasicInfo component.
 * @param {BasicProfile} props.profile - The profile data to be displayed.
 *
 * @example
 * // Example usage:
 * <BasicInfo profile={profileData} />
 *
 * @returns {JSX.Element} The rendered BasicInfo component.
 */
const BasicInfo: React.FC<BasicInfoProps> = ({ profile, setLoading, setFieldsUpdated, setShowAddIndustries }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentProfile, setCurrentProfile] = useState<BasicProfile>(profile);
    const [editedProfile, setEditedProfile] = useState<BasicProfile>(profile);
    const { addToast } = useToast();
    const nonEditableFields = ['email', 'gender', 'phoneNumber', 'country', 'interestedIndustries'];
    const validKeys: (keyof UpdateUserProfileOptions)[] = [
        'firstName',
        'lastName',
        'phoneNumber',
        'jobTitle',
        'companyName',
        'profilePhoto'
    ]

    useEffect(() => {
        setCurrentProfile(profile);
        setEditedProfile(profile);
    }, [profile]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedProfile(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSave = async () => {
        const updateUserProfileProperties: UpdateUserProfileOptions = {} as UpdateUserProfileOptions;

        for (const key in editedProfile) {
            if (
                validKeys.includes(key as keyof UpdateUserProfileOptions) &&
                editedProfile[key as keyof BasicProfile] !== currentProfile[key as keyof BasicProfile]
            ) {
                updateUserProfileProperties[key as keyof UpdateUserProfileOptions] = editedProfile[key as keyof BasicProfile] as string;
            }
        }
        if (Object.keys(updateUserProfileProperties).length === 0) {
            addToast({
                type: 'info',
                message: 'No changes detected.'
            });
            setIsEditing(false);
            return;
        }

        setLoading(true);

        try {
            const result = await updateUserProfile({ tag, data: updateUserProfileProperties });
            if (result) {
                addToast({
                    type: 'success',
                    message: 'Profile updated successfully.'
                });
                setFieldsUpdated(true);
            } else {
                addToast({
                    type: 'error',
                    message: 'We encountered an error updating your profile, please try again later.'
                });
            }
        } catch (error) {
            logError("Failed to update profile", { error }, tag);
            addToast({
                type: 'error',
                message: 'We encountered an error updating your profile, please try again later.'
            });
        } finally {
            setIsEditing(false);
            setLoading(false);
        }
    };

    const renderField = (label: string, field: keyof BasicProfile) => {
        if (field === 'interestedIndustries') return null;
        return (
            <div className={styles['basic-info-item']}>
                <p className={styles['label']}>{label}</p>
                {isEditing ? (
                    <input
                        type="text"
                        name={field}
                        disabled={nonEditableFields.includes(field)}
                        value={editedProfile[field]}
                        onChange={handleInputChange}
                        className={`${styles['input']} ${nonEditableFields.includes(field) ? styles['disabled'] : ''}`}
                    />
                ) : (
                    <p className={styles['value']}>{currentProfile[field]}</p>
                )}
            </div>
        );
    };

    return (
        <div className={styles['basic-info']}>
            <div className={`${styles['basic-info-item']} ${styles['left']}`}>
                <SecondaryButton
                    width="auto"
                    text={isEditing ? "Save Changes" : "Edit Profile"}
                    primaryColor="#FFFFFF"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={isEditing ? handleSave : () => setIsEditing(true)}
                />
            </div>
            {renderField('First name', 'firstName')}
            {renderField('Last name', 'lastName')}
            {renderField('Gender', 'gender')}
            {renderField('Email', 'email')}
            {renderField('Phone number', 'phoneNumber')}
            {renderField('Country', 'country')}
            {renderField('Company name', 'companyName')}
            {renderField('Job title', 'jobTitle')}
            <InterestedIndustries industries={currentProfile.interestedIndustries} setShowAddIndustries={setShowAddIndustries} />
        </div>
    );
};

export default BasicInfo;
