import React, { useEffect, useState } from 'react'
import styles from 'styles/components/AccountTypeForm.module.sass'
import SecondaryButton from 'components/common/SecondaryButton'
import PrimaryButton from 'components/common/PrimaryButton'
import accountTypes from 'constants/data/AccountTypes'
import AccountType from 'interface/AccountType'
import ToggleSwitch from 'components/common/ToggleSwitch'
import { useToast } from 'provider/ToastProvider'
import { useAuth } from 'hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import ZeroneLoader from 'components/common/ZeroneLoader'
import { processChangePlan } from 'api/SubscriptionApiService'
import { logError } from 'services/logService/errorLogger'

/**
 * ChangePlanForm component allows users to change their subscription plan.
 * It provides options for different account types and billing cycles.
 * 
 * @component
 * 
 * @example
 * // Renders the ChangePlanForm component
 * <ChangePlanForm />
 * 
 * @see PrimaryButton - Button component for the "Next" action
 * @see SecondaryButton - Button component for the "Update later" action
 * 
 * @description
 * This form presents users with different account types to choose from:
 * - Free: Basic access to blogs and trending reports
 * - Basic and Sapphire: Mid-tier options with additional features
 * - Gold and Platinum: Premium options with full access to services
 * 
 * Users select an account type via radio buttons. The form includes a "Next" button to proceed
 * with the selected account type and a "Update later" option for existing users.
 */
const ChangePlanForm: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    //const [accountType, setAccountType] = useState<AccountType | null>(null)
    const [selectedAccount, setSelectedAccount] = useState<'free' | 'basic' | 'sapphire' | 'gold' | 'platinum'>('basic')
    const [selectedBillingCycle, setSelectedBillingCycle] = useState<'monthly' | 'annual'>('monthly')
    const [paymentRequired, setPaymentRequired] = useState(false)
    const billingOptions = ['monthly', 'annual']

    const { addToast } = useToast()
    const { user, userSubscription } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (!user || !userSubscription) {
            addToast({
                type: 'error',
                message: 'You must be logged in to change your plan.',
            })
            navigate('/login')
            return
        }

        const currentAccountType = accountTypes.find((type) => type.name === userSubscription.name)
        if (!currentAccountType) {
            addToast({
                type: 'error',
                message: 'We encountered an error while loading your account. Please try again later.',
            })
            navigate('/')
            return
        }

        setMessage('')
        setPaymentRequired(userSubscription.paymentRequired)
        //setAccountType(currentAccountType)
        setSelectedAccount(currentAccountType.name.toLowerCase() as 'free' | 'basic' | 'sapphire' | 'gold' | 'platinum')
        setSelectedBillingCycle(userSubscription.billingCycle)
    }, [user, userSubscription])

    // useEffect(() => {
    //     setRedirectToPayment(checkIfPaymentRequired())
    // }, [selectedAccount, selectedBillingCycle])

    const handleBillingclicked = (option: string) => {
        setSelectedBillingCycle(option as 'monthly' | 'annual')
    }

    if (!user || !userSubscription ) {
        return (
            <div className={styles['account-form']}>
                <h2>Change Plan</h2>
                <p>You must be logged in to change your plan.</p>
            </div>
        )
    }

    const isUpgrade = () => {
        const currentAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === userSubscription.name.toLowerCase());
        const selectedAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === selectedAccount);

        return selectedAccountIndex > currentAccountIndex || (selectedBillingCycle === 'annual' && userSubscription.billingCycle === 'monthly');
    };

    const isDowngrade = () => {
        const currentAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === userSubscription.name.toLowerCase());
        const selectedAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === selectedAccount);

        return selectedAccountIndex < currentAccountIndex || (selectedBillingCycle === 'monthly' && userSubscription.billingCycle === 'annual');
    };

    const checkIfPaymentRequired = () => {
        const currentAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === userSubscription.name.toLowerCase());
        const selectedAccountIndex = accountTypes.findIndex((type) => type.name.toLowerCase() === selectedAccount);
    
        if (selectedAccount === 'free' || selectedAccountIndex < currentAccountIndex) {
            return false;
        }
    
        if (selectedBillingCycle === 'annual' && userSubscription.billingCycle === 'monthly') {
            return true;
        }
    
        if (userSubscription.paymentRequired) {
            return true;
        }
    
        return selectedAccountIndex !== currentAccountIndex || selectedBillingCycle !== userSubscription.billingCycle;
    };

    const handleSuccess = (): string => {
        if (!userSubscription) {
            addToast({
                type: 'error',
                message: 'We encountered an error while loading your subscription. Please try again later.',
            });
            return '/';
        }
    
        if (!selectedAccount || !['free', 'basic', 'sapphire', 'gold', 'platinum'].includes(selectedAccount)) {
            addToast({
                type: 'error',
                message: 'Invalid account selection. Please choose a valid plan.',
            });
            return '/';
        }
    
        const samePlan = selectedAccount === userSubscription.name.toLowerCase() && selectedBillingCycle === userSubscription.billingCycle;
        if (samePlan) {
            addToast({
                type: 'info',
                message: 'You are already on this plan.',
            });
            return '/';
        }
    
        setPaymentRequired(checkIfPaymentRequired());
    
        if (selectedAccount === 'free') {
            addToast({
                type: 'success',
                message: 'Your account has been downgraded to Free.',
            });
            return '/';
        }
    
        if (isDowngrade() && !paymentRequired) {
            addToast({
                type: 'success',
                message: `Your account has been downgraded to ${selectedAccount.charAt(0).toUpperCase() + selectedAccount.slice(1)} plan.`,
            });
            return '/';
        }
    
        if (isDowngrade() && paymentRequired) {
            addToast({
                type: 'success',
                message: `Your account has been downgraded to ${selectedAccount.charAt(0).toUpperCase() + selectedAccount.slice(1)} plan. Please complete the payment to finalize the change.`,
            });
            return '/subscription/payment';
        }
    
        if (isUpgrade()) {
            addToast({
                type: 'success',
                message: `Your account has been upgraded to ${selectedAccount.charAt(0).toUpperCase() + selectedAccount.slice(1)} plan. Please complete the payment to finalize the change.`,
            });
            return '/subscription/payment';
        }
    
        if (userSubscription.billingCycle === 'monthly' && selectedBillingCycle === 'annual' && paymentRequired) {
            addToast({
                type: 'success',
                message: `Your billing cycle has been changed to Annual. Please complete the payment to finalize the change.`,
            });
            return '/subscription/payment';
        }
    
        if (!paymentRequired) {
            addToast({
                type: 'success',
                message: `Your account has been updated to ${selectedAccount.charAt(0).toUpperCase() + selectedAccount.slice(1)} plan.`,
            });
            return '/';
        }
    
        addToast({
            type: 'success',
            message: `Your account has been updated to ${selectedAccount.charAt(0).toUpperCase() + selectedAccount.slice(1)} plan. Please complete the payment to finalize the change.`,
        });
        return '/subscription/payment';
    };
     

    const updatePlan = async () => {
        try {
            const result = await processChangePlan({
                tag: 'UpdateSubscription',
                data: {
                    accountType: selectedAccount,
                    billingCycle: selectedBillingCycle,
                },
            })

            if ('error' in result) {
                addToast({
                    type: 'error',
                    message: 'An error occurred while updating your plan. Please try again later.',
                })
                return;
            }

            const redirectTo = handleSuccess()
            /**
             * Trigger auth change event to update user subscription.
             * This is a workaround to update the user subscription in the auth context.
             * This is because the user subscription is not updated in the auth context after payment is successful.
            */
            window.dispatchEvent(new Event('authChange'))

            addToast({
                type: 'info',
                message: `Redirecting to ${redirectTo === '/' ? 'home' : 'payment'} page...`,
            })

            // Redirect to the appropriate page based on the result after 5 seconds
            if (redirectTo) {
                setTimeout(() => navigate(redirectTo), 5000)
            } else {
                setTimeout(() => navigate('/'), 5000)
            }
        } catch (error) {
            logError('Error updating plan', { error: error instanceof Error ? error.message : '' }, 'UpdateSubscription')
            addToast({
                type: 'error',
                message: 'An unexpected error occurred. Please try again later.',
            })
        } finally {
            setLoading(false)
        }
    }

    function handleNextClicked(event: React.FormEvent<Element>): void {
        event.preventDefault()
        setLoading(true)
        updatePlan()
    }

    return (
        <form className={styles['account-form']}>
            <h2 className={styles['form-title']}>Choose Account Type</h2>
            <div className={styles['billing-cycle-container']}>
                <p className={styles['billing-note']}>
                    All plans are <span className={styles['highlight']}>50% off now!</span> Workspace is coming soon - grab the discount before it launches at full price!
                </p>
                <ToggleSwitch
                    selectedOption={selectedBillingCycle}
                    options={billingOptions}
                    onOptionChange={handleBillingclicked}
                />
            </div>
            {/* Account Type Options */}
            <div className={styles['account-options']}>
                {accountTypes.map((account: AccountType, index: number) => (
                    <div
                        key={index}
                        className={`${styles['account-option']} ${selectedAccount === account.name ? styles['selected'] : ''}`}
                        onClick={() => setSelectedAccount(account.name)}
                    >
                        <label className={styles['radio-label']}>
                            <input
                                type="radio"
                                name="accountType"
                                value={account.name}
                                checked={selectedAccount === account.name}
                                onChange={() => setSelectedAccount(account.name)}
                                className={styles['radio-input']}
                            />
                            <span
                                className={styles['radio-text']}
                                style={{ color: account.color }}
                            >
                                {account.name}
                            </span>
                        </label>
                        <p className={styles['account-description']}>{account.description}</p>
                    </div>
                ))}
            </div>

            {/* Buttons */}
            <div className={styles['action-buttons']}>
                <PrimaryButton
                    text='Next'
                    width='auto'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    doSomething={(event: React.FormEvent) => handleNextClicked(event)}
                />
                <SecondaryButton
                    text="Update later"
                    width='auto'
                    primaryColor="transparent"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={() => navigate('/')}
                />
            </div>
            {loading && (
                <div className={styles['loader']}>
                    <ZeroneLoader text={message} />
                </div>
            )}
        </form>
    )
}

export default ChangePlanForm;