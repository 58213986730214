import { get, post } from "../ApiClient";
import EndpointProps from "interface/EndpointProps";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import { fetchApiData } from "../ApiHelper";
import UpdateUserProfileOptions from "interface/UpdateUserProfileOptions";

/**
 * The response object for the profile upload token endpoint.
 */
interface ProfileUploadTokenResponse {
    token: string;
}

/**
 * Fetches the profile of the user.
 *
 * @param {EndpointProps} params - The parameters for the API request.
 * @returns {Promise<{ success: boolean; data: ProfileUploadTokenResponse | null; error: string | null }>} A promise that resolves to an object containing the upload token or an error message.
 */
export async function fetchUploadToken({ tag }: EndpointProps): Promise<{ success: boolean; data: ProfileUploadTokenResponse | null; error: string | null }> {
    const result = await fetchApiData<DefaultApiResponse<ProfileUploadTokenResponse>>(
        tag,
        () => get<DefaultApiResponse<ProfileUploadTokenResponse>>({ tag, url: "/profile/upload/token" }),
        (responseData) => responseData.data ?? null
    );

    return {
        success: result.response.status === "success",
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.message : null,
    };
}

/**
 * Updates the profile of the user.
 *
 * @param {EndpointProps} params - The parameters for the API request.
 * @param {any} data - The data payload containing the profile details.
 * @returns {Promise<boolean>} A promise that resolves to true if the update is successful, otherwise false.
 *
 * @throws {Error} Throws an error if the fetch operation fails or if the data payload is missing.
 */
export async function updateUserProfile({
    tag,
    data,
}: EndpointProps & { data: UpdateUserProfileOptions }): Promise<boolean> {
    if (!data) {
        throw new Error("Invalid update user profile request: Missing data payload.");
    }

    const result = await fetchApiData<DefaultApiResponse>(
        tag,
        () => post<DefaultApiResponse>({ tag, url: "/profile/update", body: data }),
        (responseData) => responseData
    );

    return result.response.status === "success";
}