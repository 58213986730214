import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from 'pages/landing/LandingPage';
import './App.css';
import 'styles/tailwind/output.css'
import PricingPage from 'pages/landing/PricingPage';
import TestComponent from 'test/TestComponent';
import SignUpPage from 'pages/auth/SignUpPage';
import LoginPage from 'pages/auth/LoginPage';
import BlogPage from 'pages/blog/BlogPage';
import BlogPostPage from 'pages/blog/BlogPostPage';
import AccountTypePage from 'pages/auth/AccountTypePage';
import SuccessErrorPage from 'pages/auth/SuccessErrorPage';
import ReportCategoriesPage from 'pages/report/ReportCategoriesPage';
import DataPrivacyPage from 'pages/legal/DataPrivacyPage';
import LegalPage from 'pages/legal/LegalPage';
import TermsPage from 'pages/legal/TermsPage';
import ContactUsPage from 'layouts/section/ContactUsPage';
import WorkspacePage from 'pages/landing/WorkspacePage';
import AboutUsPage from 'pages/landing/AboutUsPage';
import ReportPage from 'pages/report/ReportPage';
import SignUpPaymentPage from 'pages/auth/SignUpPaymentPage';
import ProfilePage from 'pages/profile/ProfilePage';
import ReportPaymentPage from 'pages/report/ReportPaymentPage';
import SubscriptionPaymentPage from 'pages/user/SubscriptionPaymentPage';
import ChangePlanPage from 'pages/user/ChangePlanPage';

/**
 * App component defines the main application structure with routing setup.
 *
 * @component
 *
 * @example
 * // Renders the main application with defined routes
 * import React from 'react';
 * import App from './App';
 *
 * const MainApp = () => <App />;
 * export default MainApp;
 */
const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/** Default route [LandingPage] */}
          <Route path="/" element={<LandingPage />} />
          {/** Pricing Route */}
          <Route path="/pricing" element={<PricingPage />} />
          {/** Report Categories Route */}
          <Route path='/report/categories' element={<ReportCategoriesPage />} />
          {/** Report Route */}
          <Route path='/report/:id' element={<ReportPage />} />
          {/** Login Page Route */}
          <Route path="/login" element={<LoginPage />} />
          {/** Sign-Up Page Route */}
          <Route path="/sign-up" element={<SignUpPage />} />
          {/** Account type Page Route */}
          <Route path="/account-type" element={<AccountTypePage />} />
          {/** Signup Payment Page Route */}
          <Route path='/sign-up-payment' element={<SignUpPaymentPage />} />
          {/** Success Page Route */}
          <Route path="/success" element={<SuccessErrorPage />} />
          {/** Error Page Route */}
          <Route path="/error" element={<SuccessErrorPage />} />
          {/** Blog/ Hot-Trends Page Route */}
          <Route path="/blogs" element={<BlogPage />} />
          {/** Blog Post Page Route */}
          <Route path="/blog/:id" element={<BlogPostPage />} />
          {/** Redirect unknown route to LandingPage */}
          <Route path="*" element={<Navigate to="/" replace />} />
          {/** DataPrivacyPolicy Page Route */}
          <Route path="/privacy" element={<DataPrivacyPage />} />
          {/** Legal Page Route */}
          <Route path="/legal" element={<LegalPage />} />
          {/** About Us Page Route */}
          <Route path='/about-us' element={<AboutUsPage />} />
          {/** Terms and Conditions Page Route */}
          <Route path="/terms" element={<TermsPage />} />
          {/** Contact us Page Route */}
          <Route path="/contact-us" element={<ContactUsPage />} />
          {/** Workspace Page Route */}
          <Route path="/workspace" element={<WorkspacePage />} />
          {/** Profile Page Route */}
          <Route path="/profile" element={<ProfilePage />} />
          {/** Test Component Route */}
          <Route path="/test" element={<TestComponent />} />
          {/** Report Payment Page Route */}
          <Route path="/report/payment/:id" element={<ReportPaymentPage />} />
          {/** Subscription Payment Page Route */}
          <Route path="/subscription/payment" element={<SubscriptionPaymentPage />} />
          {/** Change Plan Page Route */}
          <Route path="/change-plan" element={<ChangePlanPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
