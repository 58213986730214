export const NAME_SANITIZATION_REGEX = /[^a-zA-Z\s'-]/g

export const EMAIL_SANITIZATION_REGEX = /[^a-zA-Z0-9@._-]/g

export const PHONE_SANITIZATION_REGEX = /[^0-9+]/g

export const INTERNATIONAL_PHONE_REGEX = /^\+?[1-9]\d{7,14}$/

export const PASSWORD_SANITIZATION_REGEX = /[<>"']/g

export const URL_SANITIZATION_REGEX = /[^a-zA-Z0-9/:._-]/g

export const sanitizeMessage = (message: string): string => {
    return message
        .trim()
        .replace(/\s+/g, ' ')
        .replace(/<[^>]*>/g, '')
        .replace(/[^a-zA-Z0-9\s.,!?'"()-]/g, '')
        .replace(/([.,!?'"()-])\1+/g, '$1')
        .replace(/\b(alert|script|on\w+)\b/gi, '')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\//g, '&#x2F;');
}