import React from 'react'
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer'
import styles from 'styles/pages/ReportPaymentPage.module.sass'
import ReportPaymentForm from 'components/forms/ReportPaymentForm'

/**
 * ReportPaymentPage component for rendering the report payment process.
 * It serves as the main entry point for users to enter their payment information
 * during the report payment process.
 *
 * @component
 * 
 * @example
 * // Render the ReportPaymentPage
 * <ReportPaymentPage />
 * 
 * @see AnimatedBackgroundContainer - Component providing an animated background and optional footer.
 * @see ReportPaymentForm - The form component for handling user payment input during report payment.
 */
const ReportPaymentPage: React.FC = () => {
    return (
        <div className={styles['report-payment-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <ReportPaymentForm />
            </AnimatedBackgroundContainer>
        </div>
    )
}

export default ReportPaymentPage;