import { getBlogs, getHotTopic, getBlogPost } from "./endpoints/BlogEndpoints";
import { getReportCategories, getTopHotReports, getReport, checkAccessToReport, downloadReport, purchaseReportUsingCredits, getReportCatalog, purchaseReportUsingPayment, updateReportPurchaseStatus } from "./endpoints/ReportEndpoints";
import { signUpUser, logInUser } from "./endpoints/AuthEndpoints";
import { updateSubscriptionPayment, updateSubscriptionPlan, fetchSubscriptionData, getSubscriptionInvoices, checkOutSubscription } from "./endpoints/SubscriptionEndpoints";
import { fetchAccountData } from "./endpoints/AccountEndpoints";
import { fetchDefaultPaymentMethod } from "./endpoints/PaymentEndpoints";
import { addToWaitlist } from "./endpoints/WaitlistEndpoints";
import { submitContactInquiry } from "./endpoints/ContactEndpoints";
import { fetchMaintenanceStatus } from "./endpoints/MaintenanceEndpoints";
import { fetchUploadToken, updateUserProfile } from "./endpoints/ProfileEndpoints";
import { getAllIndustries, removeInterestedIndustry, addInterestedIndustries } from "./endpoints/IndustriesEndpoints";

/**
 * Interface representing the endpoints related to account operations.
 */
interface AccountEndpoints {
    fetchAccountData: typeof fetchAccountData;
}

/**
 * Interface representing the endpoints related to subscription operations.
 * 
 * @interface SubscriptionEndpoints
 * 
 * @property {typeof updateSubscriptionPlan} updateSubscriptionPlan - Endpoint for updating the subscription plan.
 * @property {typeof updateSubscriptionPayment} updateSubscriptionPayment - Endpoint for updating the subscription payment details.
 * @property {typeof fetchSubscriptionData} fetchSubscriptionData - Endpoint for fetching subscription data.
 */
interface SubscriptionEndpoints {
    updateSubscriptionPlan: typeof updateSubscriptionPlan;
    updateSubscriptionPayment: typeof updateSubscriptionPayment;
    fetchSubscriptionData: typeof fetchSubscriptionData;
    getSubscriptionInvoices: typeof getSubscriptionInvoices;
    checkOutSubscription: typeof checkOutSubscription;
}

/**
 * Interface for available auth-related endpoints.
 * 
 * @interface AuthEndpoints
 * @property {typeof signUpUser} signUpUser - Function for registering user.
 */
interface AuthEndpoints {
    signUpUser: typeof signUpUser;
    logInUser: typeof logInUser;
}

/**
 * Interface for available blog-related endpoints.
 * 
 * @interface BlogEndpoints
 * @property {typeof getBlogs} getBlogs - Function for fetching blog data.
 * @property {typeof getHotTopic} getHotTopic - Function for fetching hot topc.
 * @property {typeof getBlogPost} getBlogPost - Function for fetching blog post.
 */
interface BlogEndpoints {
    getBlogs: typeof getBlogs;
    getHotTopic: typeof getHotTopic;
    getBlogPost: typeof getBlogPost;
}

/**
 * Interface for available report-related endpoints.
 * 
 * @interface ReportEndpoints
 * @property {typeof getReportCategories} getReportCategories - Function for fetching report categories.
 */
interface ReportEndpoints {
    getReportCategories: typeof getReportCategories;
    getTopHotReports: typeof getTopHotReports;
    getReport: typeof getReport;
    checkAccessToReport: typeof checkAccessToReport;
    downloadReport: typeof downloadReport;
    purchaseReportUsingCredits : typeof purchaseReportUsingCredits;
    purchaseReportUsingPayment: typeof purchaseReportUsingPayment;
    getReportCatalog: typeof getReportCatalog;
    updateReportPurchaseStatus: typeof updateReportPurchaseStatus;
}

/**
 * Interface for available payment-related endpoints.
 * 
 * @interface PaymentEndpoints
 * @property {typeof fetchDefaultPaymentMethod} fetchDefaultPaymentMethod - Function for fetching the default payment method.
 */
interface PaymentEndpoints {
    fetchDefaultPaymentMethod: typeof fetchDefaultPaymentMethod;
}

/**
 * Interface for available waitlist-related endpoints.
 * 
 * @interface WaitlistEndpoints
 * @property {typeof addToWaitlist} addToWaitlist - Function for adding a user to the waitlist.
 */
interface WaitlistEndpoints {
    addToWaitlist: typeof addToWaitlist;
}

/**
 * Interface for available contact-related endpoints.
 * 
 * @interface ContactEndpoints
 * @property {typeof submitContactInquiry} submitContactInquiry - Function for submitting a contact inquiry.
 */
interface ContactEndpoints {
    submitContactInquiry: typeof submitContactInquiry;
}

/**
 * Interface for available maintenance-related endpoints.
 * 
 * @interface MaintenanceEndpoints
 * @property {typeof fetchMaintenanceStatus} fetchMaintenanceStatus - Function for fetching maintenance status.
 */
interface MaintenanceEndpoints {
    fetchMaintenanceStatus: typeof fetchMaintenanceStatus;
}

/**
 * Interface for available profile-related endpoints.
 * 
 * @interface ProfileEndpoints
 * @property {typeof fetchUploadToken} fetchUploadToken - Function for fetching the profile upload token.
 * @property {typeof updateUserProfile} updateUserProfile - Function for updating the user profile.
 */
interface ProfileEndpoints {
    fetchUploadToken: typeof fetchUploadToken;
    updateUserProfile: typeof updateUserProfile;
}

/**
 * Interface for available industries-related endpoints.
 * 
 * @interface IndustriesEndpoints
 * @property {typeof getAllIndustries} getAllIndustries - Function for fetching all industries.
 */
interface IndustriesEndpoints {
    getAllIndustries: typeof getAllIndustries;
    removeInterestedIndustry: typeof removeInterestedIndustry;
    addInterestedIndustries: typeof addInterestedIndustries;
}

/**
 * Main interface for all available endpoints.
 * 
 * @interface Endpoints
 * @extends AuthEndpoints
 * @extends BlogEndpoints
 * @extends ReportEndpoints
 * @extends PaymentEndpoints
 * @extends SubscriptionEndpoints
 * @extends AccountEndpoints
 * @extends WaitlistEndpoints
 * @extends ContactEndpoints
 * @extends MaintenanceEndpoints
 * @extends ProfileEndpoints
 * @extends IndustriesEndpoints
 */
interface Endpoints extends AuthEndpoints, SubscriptionEndpoints, AccountEndpoints, BlogEndpoints, ReportEndpoints, PaymentEndpoints, WaitlistEndpoints, ContactEndpoints, MaintenanceEndpoints, ProfileEndpoints, IndustriesEndpoints {}

/**
 * Centralized object containing all endpoint functions.
 * This object provides easy access to different API endpoint functions throughout the application.
 * 
 * @constant
 * @type {Endpoints}
 * 
 * @example
 * // Import the endpoints object and use it to fetch blogs
 * import endpoints from './constants/api/endpoints';
 * 
 * async function fetchBlogData() {
 *   const result = await endpoints.getBlogs({ tag: 'BlogFetch' });
 *   if (result.error) {
 *     console.error('Error fetching blogs:', result.error);
 *   } else {
 *     console.log('Fetched blogs:', result.data);
 *   }
 * }
 * 
 * fetchBlogData();
 */
const endpoints: Endpoints = {
    signUpUser,
    logInUser,
    updateSubscriptionPlan,
    updateSubscriptionPayment,
    fetchSubscriptionData,
    fetchAccountData,
    getBlogs,
    getHotTopic,
    getBlogPost,
    getReportCategories,
    getTopHotReports,
    getReport,
    checkAccessToReport,
    downloadReport,
    purchaseReportUsingCredits,
    getReportCatalog,
    getSubscriptionInvoices,
    fetchDefaultPaymentMethod,
    purchaseReportUsingPayment,
    updateReportPurchaseStatus,
    addToWaitlist,
    submitContactInquiry,
    checkOutSubscription,
    fetchMaintenanceStatus,
    fetchUploadToken,
    updateUserProfile,
    getAllIndustries,
    removeInterestedIndustry,
    addInterestedIndustries
};

export default endpoints;