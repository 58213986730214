import endpoints from "constants/api/Endpoints";
import UpdatePayment from "interface/UpdatePayment";
import { logError } from "services/logService/errorLogger";
import { LowLevelPaymentMethod } from "interface/LowLevelPaymentMethod";
import { DefaultApiServiceReturnType } from "constants/api/models/response/DefaultApiResponse";

const tag = 'PaymentApiService'

/**
 * Updates subscription payment status.
 *
 * @async
 * @param {UpdatePayment} params Includes the payment Id and the status of the transaction.
 * @returns {Promise<boolean>} 
 */
export const processSubscriptionResponse = async (params: UpdatePayment): Promise<boolean> => {
    try {
        if (!params) {
            logError('Missing payment payload.', params, tag)
            return false
        }
        
        const result = await endpoints.updateSubscriptionPayment({ tag: tag, data: params })
        return result
    } catch (error) {
        logError(
            'Error updating payment response',
            { error, params },
            tag
        )
        return false
    }
}

/**
 * Fetches the default payment method.
 *
 * @async
 * @param {string} tag The tag for the endpoint.
 * @returns {Promise<LowLevelPaymentMethod | null>} A promise that resolves to the default payment method or null.
 * @throws Logs an error if the fetch operation fails.
 * 
 * @example
 * const paymentMethod = await fetchDefaultPaymentMethod('PaymentMethod');
 * console.log('Payment method:', paymentMethod);
 */
export async function fetchDefaultPaymentMethod(tag: string): Promise<LowLevelPaymentMethod | null> {

    try {
        const result = await endpoints.fetchDefaultPaymentMethod({ tag }) as DefaultApiServiceReturnType<LowLevelPaymentMethod>;
        if (result && result.success && result.data) {
            return result.data ? result.data : null
        }
        logError('Failed to fetch default payment method', { error: result.error }, tag)
        return null
    } catch (error) {
        logError('Error fetching default payment method', { error }, tag)
        return null
    }
}