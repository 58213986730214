import React, { useEffect, useState } from 'react'
import styles from 'styles/components/PaymentForm.module.sass'
import { Link, useNavigate } from 'react-router-dom';
import ZeroneLoader from 'components/common/ZeroneLoader';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import accountTypes from 'constants/data/AccountTypes';
import AccountType from 'interface/AccountType';
import { useToast } from 'provider/ToastProvider';
import { checkOutSubscription } from 'api/SubscriptionApiService';
import PrimaryButton from 'components/common/PrimaryButton';
import { useAuth } from 'hooks/AuthContext';
import StripePaymentIntentResult from 'interface/StripePaymentIntentResult';
import StripePaymentForm from './StripePaymentForm';
import { logError } from 'services/logService/errorLogger';
import { processSubscriptionResponse } from 'api/PaymentApiService';
import stripeErrorMessages from 'constants/data/StripeErrors';

type Discount = {
    percent: number;
    originalAmount: number;
    discountAmount: number;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '')
const currency = 'USD'

const SubscriptionPaymentForm: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState<number>(0)
    const [accountType, setAccountType] = useState<AccountType | null>(null)
    const [paymentIntent, setPaymentIntent] = useState<StripePaymentIntentResult | null>(null)
    const [clientSecret, setClientSecret] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [discount, setDiscount] = useState<Discount | null>(null)
    const { addToast } = useToast()
    const { user, userSubscription} = useAuth()

    //const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!user || !userSubscription) {
            addToast({
                type: 'error',
                message: 'Please login to access this page'
            })
            navigate('/login')
            return
        }
        if (userSubscription && userSubscription.name === 'free') {
            addToast({
                type: 'error',
                message: 'Please upgrade your account to access this page'
            })
            navigate('/')
            return
        }
        if (userSubscription && !userSubscription.paymentRequired) {
            addToast({
                type: 'error',
                message: 'Your account is already active. Please login to access your account'
            })
            navigate('/login')
            return
        }

        const currentAccountType = accountTypes.find((type) => type.name === userSubscription?.name)
        if (!currentAccountType) {
            addToast({
                type: 'error',
                message: 'We encountered an issue while processing your request. Please try again later'
            })
            navigate('/')
            return
        }

        setAccountType(currentAccountType)

        const getCheckoutDetails = async () => {
            setLoading(true)
            setMessage('Loading payment details...')
            try {
                const response : StripePaymentIntentResult | null = await checkOutSubscription({ tag: 'SubscriptionPaymentForm' })
                if (!response) {
                    addToast({
                        type: 'error',
                        message: 'Something went wrong. Please try again later'
                    })
                    setMessage('An error occurred while processing your request. Please try again later')
                    navigate('/')
                    return
                }
                setPaymentIntent(response)
                setClientSecret(response.clientSecret)
                setDiscount(response.discount ? response.discount : null)
                setAmount(response.amount)
            } catch (error) {
                addToast({
                    type: 'error',
                    message: 'Something went wrong. Please try again later'
                })
                logError('Error fetching checkout details', { error }, 'SubscriptionPaymentForm')
                setMessage('An error occurred while processing your request. Please try again later')
                navigate('/')
            } finally {
                setLoading(false)
            }
        }

        getCheckoutDetails()

    }, [user, userSubscription])

    const handleChangePlan = () => {
        addToast({
            type: 'info',
            message: 'Redirecting to change plan page...'
        })
        navigate('/change-plan')
    }

    if (userSubscription && userSubscription.name === 'free' && !userSubscription.paymentRequired) {
        return (
            <div className={styles['payment-form']}>
                <h1>Welcome to Zerone</h1>
                <p>Your account is already active. Please login to access your account</p>
                <Link to='/login'>Login</Link>
            </div>
        )
    }

    const handlePaymentSuccess = async (paymentId: string): Promise<void> => {
        setLoading(true)
        try {
            const response = await processSubscriptionResponse({ paymentId, status: 'succeeded' })
            if (!response) {
                addToast({
                    type: 'error',
                    message: 'Your payment is being processed. You will receive an email once it is completed'
                })
                setMessage('Your payment is being processed. You will receive an email once it is completed')
            } else {
                addToast({
                    type: 'success',
                    message: 'Your payment was successful. You will receive an email confirmation shortly'
                })
                setMessage('Your payment was successful. You will receive an email confirmation shortly')
                
                /**
                 * Trigger auth change event to update user subscription.
                 * This is a workaround to update the user subscription in the auth context.
                 * This is because the user subscription is not updated in the auth context after payment is successful.
                */
                window.dispatchEvent(new Event('authChange'))
            }
            setLoading(false)
            navigate('/success', {
                state: {
                    title: 'Payment Successful',
                    message: 'Your payment was successful. You will receive an email confirmation shortly',
                    note: 'Thank you for choosing Zerone. We look forward to serving you.',
                    redirectTo: '/',
                    messageType: 'success'
                }
            })
        } catch (error) {
            addToast({
                type: 'error',
                message: 'Your payment is being processed. You will receive an email once it is completed'
            })
            setMessage('Your payment is being processed. You will receive an email once it is completed')
            logError('Error processing payment response', { error }, 'SubscriptionPaymentForm')
        } finally {
            setLoading(false)
        }
    }

    const handlePaymentFailure = async (errorMessage: string) => {
        setLoading(true)
        try {
            await processSubscriptionResponse({ paymentId: '', status: 'failed' })
            setLoading(false)

            if (typeof errorMessage !== 'string' || !errorMessage) {
                addToast({
                    type: 'error',
                    message: 'An error occurred while processing your payment. Please try again later'
                })
                return
            }

            const error = errorMessage.toLocaleLowerCase()
            const defaultErrorMessage = 'Payment failed. Please contact support if the issue persists.'

            const errorKey = Object.keys(stripeErrorMessages).find((key) =>
                typeof error === 'string' && error.includes(key)
            )

            if (errorKey) {
                addToast({
                    type: 'error',
                    message: stripeErrorMessages[errorKey] || defaultErrorMessage
                })
            } else {
                addToast({
                    type: 'error',
                    message: defaultErrorMessage
                })
            }
            return;
        } catch (error) {
            addToast({
                type: 'error',
                message: 'An error occurred while processing your payment. Please try again later'
            })
            logError('Error processing payment failure', { error }, 'SubscriptionPaymentForm')
        } finally {
            setLoading(false)
        }
    }

    const handleCancelPayment = async () => {
        setLoading(true)
        try {
            await processSubscriptionResponse({ paymentId: '', status: 'canceled' })
            setMessage('Payment process canceled. Your account has been reverted to the free plan. You can upgrade your account at any time in your profile section.')
            addToast({
                type: 'warning',
                message: 'Payment process canceled. Your account has been reverted to the free plan. You can upgrade your account at any time in your profile section.'
            })
            navigate('/success', {
                state: {
                    message,
                    redirectTo: '/',
                    messageType: 'success'
                }
            })
            return
        } catch (error) {
            addToast({
                type: 'error',
                message: 'An error occurred while processing your payment. Please try again later'
            })
            logError('Error processing payment cancellation', { error }, 'SubscriptionPaymentForm')
            navigate('/error', {
                state: {
                    title: 'Payment Canceled',
                    message: 'Your payment process has been canceled. Your account has been reverted to the free plan. You can upgrade your account at any time in your profile section.',
                    note: 'Thank you for choosing Zerone. We look forward to serving you.',
                    redirectTo: '/',
                    messageType: 'warning'
                }
            })
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div className={styles['main-payment-form']}>
            <div className={styles['header-container']}>  
                <div className={styles['logo-container']}>
                    <Link to="/">
                        <img src={zeroneLogoDark} className={styles['zerone-logo']} alt="Zerone AnalytiQs Logo" />
                    </Link>
                </div>
                <h2 className={styles['form-title']}>{!loading ? 'Enter payment information' : ''}</h2>
                <p className={styles['form-subtitle']}>{!loading ? `We will automatically bill each ${userSubscription?.billingCycle == 'monthly' ? 'month' : 'year'}.` : ''}</p>
            </div>
            {loading ? (
                <div className={styles['loader']}>
                    <ZeroneLoader text={message} />
                </div>
            ) : paymentIntent && paymentIntent.clientSecret ? (
                <div className={styles['main-wrapper']}>
                    <div className={styles['summary-section']}>
                        <div className={styles['top-summary']}>
                            <label className={styles['radio-label']}>
                                <input
                                    type="radio"
                                    name="accountType"
                                    value={userSubscription?.billingCycle}
                                    checked={true}
                                    className={styles['radio-input']}
                                />
                                <span 
                                    className={styles['radio-text']}
                                    style={{ color: accountType?.color }}
                                >
                                    {accountType?.name}
                                </span>
                            </label>
                            <div className={styles['right']}>
                                <p className={styles['amount']}>{currency} {isNaN(amount) ? '0.00': amount.toFixed(2) || '0.00'}</p>
                                <span className={styles['subscription-type']}>{userSubscription?.billingCycle === 'monthly' ? 'per month' : 'per year'}</span>
                            </div>
                        </div>
                        <p className={styles['account-description']}>{accountType?.description}</p>
                        <span className={styles['change-plan']} onClick={handleChangePlan}>Change plan</span>
                    </div>
                    <div className={styles['divider']} />
                    <div className={styles['payment-section']}>
                        {discount !== null ? (
                            <div className={styles['discount-table']}>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Standard Price:</div>
                              <div className={styles['table-cell']}>{currency} {discount.originalAmount.toFixed(2)}</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Discount Applied:</div>
                              <div className={styles['table-cell']}>{discount.percent}%</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Discount Amount:</div>
                              <div className={styles['table-cell']}>{currency} {discount.discountAmount.toFixed(2)}</div>
                            </div>
                            <div className={styles['table-row']}>
                              <div className={styles['table-cell']}>Total After Discount:</div>
                              <div className={styles['table-cell']}>{currency} {(discount.originalAmount - discount.discountAmount).toFixed(2)}</div>
                            </div>
                          </div>
                        ) : null}
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <StripePaymentForm
                                clientSecret={clientSecret}
                                onPaymentSuccess={(paymentId) => { handlePaymentSuccess(paymentId) }}
                                onPaymentFailure={(errorMessage) => { void handlePaymentFailure(errorMessage) }}
                                onCancelPayment={() => { void handleCancelPayment(); }}
                            />
                        </Elements>
                    </div>
                </div>
            ) : (
                <div className={styles['message-section']}>
                    <p className={styles['message']}>{message}</p>
                    {message.toLowerCase().includes('log in') && (            
                        <PrimaryButton
                            text='Login'
                            primaryColor='#FF5522'
                            textColor='#FFFFFF'
                            hoverColor='#FFFFFF'
                            hoverTextColor='#FF5522'
                            doSomething={() => navigate('/login')}
                        />
                    )}
                </div>
            )}
        </div>
    )

}

export default SubscriptionPaymentForm;

