import React from 'react';
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer';
import ChangePlanForm from 'components/forms/ChangePlanForm';
import styles from 'styles/pages/ChangePlanPage.module.sass';

/**
 * ChangePlanPage component for rendering the change plan page.
 * It serves as the main entry point for users to change their subscription plan.
 *
 * @component
 * 
 * @example
 * // Render the ChangePlanPage
 * <ChangePlanPage />
 * 
 * @see AnimatedBackgroundContainer - Component providing an animated background and optional footer.
 * @see ChangePlanForm - The form component for handling user input during plan changes.
 */
const ChangePlanPage: React.FC = () => {
    return (
        <div className={styles['change-plan-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <ChangePlanForm />
            </AnimatedBackgroundContainer>
        </div>
    );
}

export default ChangePlanPage;