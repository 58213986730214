import { NAME_SANITIZATION_REGEX, EMAIL_SANITIZATION_REGEX, sanitizeMessage, PHONE_SANITIZATION_REGEX } from "constants/RegexConstants";
import Inquiry from "interface/Inquiry";

/**
 * Validates and sanitizes the contact inquiry form data.
 * 
 * @param {Inquiry} inquiry - The inquiry form data.
 * @param {string} inquiry.firstName - The first name of the person making the inquiry.
 * @param {string} inquiry.lastName - The last name of the person making the inquiry.
 * @param {string} inquiry.email - The email address of the person making the inquiry.
 * @param {string} [inquiry.phoneNumber] - The phone number of the person making the inquiry (optional).
 * @param {string} inquiry.message - The message or inquiry content.
 * 
 * @returns {{
 *   isValid: boolean,
 *   errors: string[],
 *   sanitizedData: Inquiry
 * }} - Returns validation results, errors, and sanitized fields.
 */
export const validateAndSanitizeContactInquiry = ({
    firstName,
    lastName,
    email,
    phoneNumber,
    message,
}: Inquiry) => {
    const errors: string[] = [];
    let isValid = true;

    // Validate and sanitize first name
    if (!firstName || firstName.trim() === '') {
        errors.push('First name is required.');
        isValid = false;
    } else {
        firstName = firstName.replace(NAME_SANITIZATION_REGEX, '').trim();
    }

    // Validate and sanitize last name
    if (!lastName || lastName.trim() === '') {
        errors.push('Last name is required.');
        isValid = false;
    } else {
        lastName = lastName.replace(NAME_SANITIZATION_REGEX, '').trim();
    }

    // Validate and sanitize email
    if (!email || email.trim() === '') {
        errors.push('Email is required.');
        isValid = false;
    } else {
        email = email.replace(EMAIL_SANITIZATION_REGEX, '').trim();
    }

    // Validate and sanitize phone number
    if (phoneNumber && phoneNumber.trim() !== '') {
        phoneNumber = phoneNumber.replace(PHONE_SANITIZATION_REGEX, '').trim();
    } else {
        phoneNumber = '';
    }

    // Validate and sanitize message
    if (!message || message.trim() === '') {
        errors.push('Message is required.');
        isValid = false;
    } else {
        message = sanitizeMessage(message);
    }

    return {
        isValid,
        errors,
        sanitizedData: {
            firstName,
            lastName,
            email,
            phoneNumber,
            message,
        },
    };
}