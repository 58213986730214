import { LowLevelPaymentMethod } from "interface/LowLevelPaymentMethod";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import { fetchApiData } from "../ApiHelper";
import { get } from "../ApiClient";
import EndpointProps from "interface/EndpointProps";

/**
 * Fetches the default payment method.
 *
 * @param {Object} params - The parameters for fetching the default payment method.
 * @param {string} params.tag - The tag associated with the endpoint.
 * @returns {Promise<{ success: boolean; data: LowLevelPaymentMethod | null; error: string | null }>} A promise that resolves to an object containing the response data or an error message.
 *
 * @throws {Error} Throws an error if the fetch operation fails.
 */
export async function fetchDefaultPaymentMethod({ tag }: EndpointProps): Promise<{ success: boolean; data: LowLevelPaymentMethod | null; error: string | null }> {
    const result = await fetchApiData<DefaultApiResponse<LowLevelPaymentMethod>>(
        tag,
        () => get<DefaultApiResponse<LowLevelPaymentMethod>>({ tag, url: "/payment/method/default" }),
        (responseData) => responseData.data ?? null
    )

    return {
        success: result.response.status === "success",
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.message : null
    }
}