import React, { useState } from 'react'
import styles from 'styles/layout/ProfileBodySection.module.sass'
import BasicProfile from 'interface/BasicProfile';
import BasicInfo from 'components/layout/profile/BasicInfo';
import MyReports from 'components/layout/profile/MyReports';
import AccountDetails from 'components/layout/profile/AccountDetails';
import ZeroneLoader from 'components/common/ZeroneLoader';
import AddInterestedIndustriesCard from 'components/cards/AddInterestedIndustriesCard';

interface ProfileBodySectionProps {
    profile: BasicProfile | null;
    tab: 'basic' | 'reports' | 'account';
    onCancelSubscription?: () => void;
    setFieldsUpdated: (updated: boolean) => void;
}

/**
 * ProfileBodySection component - Displays the body section for the profile page.
 *
 * This component includes a summary of the profile (`BasicProfile`). The profile
 * summary is rendered only if a valid profile is passed as a prop.
 *
 * @component
 * @param {ProfileBodySectionProps} props - The properties for the ProfileBodySection component.
 * @param {'basi' | 'reports' | 'account'} props.tab - The tab to be displayed in the body section.
 * @param {BasicProfile | null} props.profile - The profile data to be displayed in the body section.
 * @param {() => void} [props.onCancelSubscription] - Optional callback function to handle subscription cancellation.
 * @param {(updated: boolean) => void} props.setFieldsUpdated - A function to set the fields updated state.
 *
 * @example
 * // Example usage:
 * <ProfileBodySection profile={profileData} />
 *
 * @returns {JSX.Element} The rendered ProfileBodySection component.
 */
const ProfileBodySection: React.FC<ProfileBodySectionProps> = ({ profile, tab, onCancelSubscription, setFieldsUpdated }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [showAddIndustries, setShowAddIndustries] = useState<boolean>(false);

    const handleCancelSubscription = () => {
        if (onCancelSubscription) {
            onCancelSubscription();
        }
    }

    return (
        <section className={styles['profile-body-section']}>
            {profile && !loading ? (
                tab === 'basic' ? (
                    <BasicInfo profile={profile} setLoading={setLoading} setFieldsUpdated={setFieldsUpdated} setShowAddIndustries={setShowAddIndustries} />
                ) :
                tab === 'reports' ? (
                    <MyReports />
                ) :
                tab === 'account' ? (
                    <AccountDetails
                        onCancelSubscription={handleCancelSubscription}
                    />
                ) : null
            ) : loading ? (
                <div className={styles['overlay']}>
                    <ZeroneLoader />
                </div>
            ) : (
                <div className={styles['no-profile-container']}>
                    <p>No profile data available please try again later.</p>
                </div>
            )}
            {showAddIndustries && (
                <div className={styles['overlay']}>
                    <AddInterestedIndustriesCard 
                        setShowAddIndustries={setShowAddIndustries} 
                        userIndustries={profile?.interestedIndustries || []}
                        setLoading={setLoading}
                        setFieldsUpdated={setFieldsUpdated}
                    />
                </div>
            )}
        </section>
    )
}

export default ProfileBodySection;